<template>
    <core-layout
        class="onboarding"
        :show-footer="false"
    >
        <template v-slot:navigation>
            <navigation-left
                :can-collapse="false"
                :allow-back-button="false"
            />
        </template>

        <template v-slot:default>
            <asc-section
                data-width="content-sm"
                class="onboarding__nav"
            >
                <nav>
                    <ul>
                        <li>
                            <nuxt-link to="/onboarding" title="Step 1: follow topics">
                                <span class="sr-only">Step 1: follow topics</span>
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link to="/onboarding/profile" title="Step 2: update your profile">
                                <span class="sr-only">Step 2: update your profile</span>
                            </nuxt-link>
                        </li>
                    </ul>
                </nav>
            </asc-section>

            <nuxt class="onboarding__container" keep-alive />
        </template>
    </core-layout>
</template>

<script>
import CoreLayout from '~/layouts/_core.vue';
import NavigationLeft from '~/patterns/organisms/navigation/navigation-left.vue';
import AscSection from '~/patterns/organisms/section/section.vue';

export default {
    components: {
        CoreLayout,
        NavigationLeft,
        AscSection
    }
};
</script>

<style lang="less">
.onboarding {
    .article h1 {
        .typography-h1();
    }
}

.section.onboarding__nav {
    flex: 0 0 auto;
    padding: var(--spacing-xl) 0 0 0;

    ul {
        .list--clean();
        display: flex;
        align-items: center;
    }

    li a {
        display: block;
        width: .5rem;
        height: .5rem;

        border-radius: 50%;
        background: var(--color-black-20);
        transition: var(--transition-link);

        &.nuxt-link-exact-active {
            background: var(--color-black-80);
        }
    }

    li + li {
        margin-left: var(--spacing-base);
    }
}

.onboarding__container {
    .section:first-child {
        padding-top: var(--spacing-xl);
    }
}
</style>
