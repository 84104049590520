export default {
    title: 'Amsterdam Smart City',
    description: 'Amsterdam Smart City is an urban open innovation platform for change makers to meet, interact and collaborate. Let’s create better streets, neighbourhoods and cities!',
    shareImage: 'https://amsterdamsmartcity.com/social-image.jpg',
    author: 'Amsterdam Smart City',

    twitter: {
        handle: '@adamsmartcity'
    }
};
