<template>
    <div class="section">
        <content-layout>
            <template
                v-if="!!$slots.sidebar"
                v-slot:sidebar
            >
                <div class="section__sidebar">
                    <slot name="sidebar" />
                </div>
            </template>

            <template v-slot:default>
                <div
                    v-if="!!$slots.title"
                    class="section__title"
                >
                    <slot name="title" />
                </div>

                <div class="section__body">
                    <slot />
                </div>
            </template>
        </content-layout>
    </div>
</template>

<script>
import ContentLayout from '~/patterns/organisms/content-layout/content-layout.vue';

export default {
    components: {
        ContentLayout
    }
};
</script>

<style lang="less" src="./section.less" />
