<template>
    <asc-icon :svg-attrs="{ width: 24, height: 24 }" viewbox="0 0 24 24">
        <template v-slot:title>Experience</template>
        <template v-slot:default>
            <path d="M12 2C6.48 2 2 6.48 2 12V22H12C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM8.94 16.81V7.79L16.46 12.3L8.94 16.81Z" fill="#180032" />
        </template>
    </asc-icon>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon }
};
</script>
