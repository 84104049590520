<template>
    <asc-modal
        :is-visible="isOpen"
        data-modal-name="edit-update"
        @close="closeModal"
    >
        <template v-slot:header>
            <h1 v-if="update">Edit {{ update.update_type }}</h1>
        </template>

        <template v-slot:default>
            <asc-update-form
                v-if="update"
                v-model="formData"
                :type="update.update_type"
                :is-loading="isLoading"
                @submit="submitData"
                @hasChanges="onHasFormChanges"
            />
        </template>
    </asc-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import editUpdateMutation from '~/graphql/mutations/update/editUpdate.graphql';
import formatGqlError from '~/helpers/formatGqlError.js';
import { sanitizeUpdateFormData } from '~/helpers/update.js';

import AscModal from '~/patterns/organisms/modal/modal.vue';
import AscUpdateForm from '~/patterns/organisms/update-form/update-form.vue';

export default {
    components: {
        AscModal,
        AscUpdateForm
    },

    data() {
        return {
            isLoading: false,
            formData: null,
            hasChanges: false
        };
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal',
            modalData: 'modal/data'
        }),

        isOpen() {
            return this.activeModal === 'edit-update';
        },

        update() {
            if (!this.modalData || !this.modalData.update) {
                return;
            }

            return this.modalData.update;
        },

        sanitizedData() {
            const data = sanitizeUpdateFormData(this.formData);

            // Unset featured image if it didn't change
            if (data.featured_image === this.update.featured_image) {
                delete data.featured_image;
            }

            // Set update ID
            data.id = this.update.id;

            // Set update type
            data.update_type = this.update.update_type;

            return data;
        }
    },

    watch: {
        update(value) {
            if (!value) {
                this.formData = null;
            }

            this.formData = { ...value };
        }
    },

    methods: {
        /**
         * Close and reset the modal.
         * If any changes were made, make the user confirm before closing.
         */
        closeModal() {
            let confirmed = true;

            if (this.hasChanges) {
                confirmed = confirm('Are you sure you want to stop editing your update? Your changes will be lost.');
            }

            if (confirmed) {
                this.$store.dispatch('modal/close');
                this.resetModal();
            }
        },

        /**
         * Reset the add update modal to its initial state.
         */
        resetModal() {
            this.isLoading = false;
            this.formData = null;
            this.hasChanges = false;
        },

        /**
         * Submit the data gathered in the different steps to the graphQL api.
         */
        async submitData() {
            this.isLoading = true;

            try {
                await this.$gql.executeQuery(editUpdateMutation, this.sanitizedData);

                this.$router.go();

                setTimeout(() => {
                    // Refresh the page to display the new data.

                    // Reset the modal for reuse
                    this.resetModal();
                }, 2000);
            } catch (e) {
                this.isLoading = false;

                const messages = formatGqlError(e);

                messages.forEach((message) => {
                    this.$store.commit('toasts/addMessage', {
                        message: message.text,
                        status: 'error'
                    });
                });
            }
        },

        /**
         * Keep track of whether the update-form has any changes.
         * This value can be used to check if a prompt should be displayed
         * before closing the modal.
         */
        onHasFormChanges(value) {
            this.hasChanges = value;
        }
    }
};
</script>
