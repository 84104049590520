export default function({ app, store }) {
    app.router.afterEach((to, from) => {
        if (to.path !== from.path) {
            // Close the menu overlay
            if (store.getters['ui/isMenuOpen']) {
                store.dispatch('ui/closeMenu');
            }

            // Close any open modals
            if (store.getters['modal/activeModal'] !== 'cookie-modal') {
                store.dispatch('modal/close');
            }
        }
    });
}
