<template>
    <asc-follow
        v-if="topic"
        v-slot="{ clickHandler: followOrUnfollowTopic }"
        following-type="TOPIC"
        :following-id="Number(topic.id)"
        :following-name="topic.title"
        :following="topic.userIsFollowing"
        class="follow-topic-button"
        :class="{ 'follow-topic-button--dual-purpose': dualPurpose }"
        @update:following="updateTopic"
    >
        <asc-button
            class="dn-button--inline dn-button--flat"
            :class="{ 'dn-button--channel': !topic.userIsFollowing }"
            :data-is-following="topic.userIsFollowing"
            :tint="buttonTint"
            :title="!dualPurpose ? topic.userIsFollowing ? 'Unfollow' : 'Follow' : 'View updates'"
            v-on="getButtonListeners(followOrUnfollowTopic)"
        >
            <template v-slot:default>
                <div @click="dualPurpose ? $router.push(`/topic/${topic.slug}`) : null">
                    {{ topic.title }}
                </div>
            </template>

            <template v-slot:after>
                <asc-icon
                    :title="dualPurpose ? topic.userIsFollowing ? 'Unfollow' : 'Follow' : null"
                    :icon="topic.userIsFollowing ? (hoverActive ? 'cross' : 'check') : 'plus'"
                    @click.native="followOrUnfollowTopic"
                    @mouseover.native="dualPurpose ? hoverActive = true : null"
                    @mouseout.native="dualPurpose ? hoverActive = false : null"
                />
            </template>
        </asc-button>
    </asc-follow>
</template>

<script>
// @TODO: Use asc-topic-button instead
import AscButton from '~/patterns/atoms/button/button.vue';
import AscFollow from '~/patterns/atoms/follow/follow.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscFollow,
        AscButton,
        AscIcon
    },

    props: {
        tint: {
            type: String,
            required: false,
            default: null
        },
        topic: {
            type: Object,
            required: true,
            default: null
        },
        // With this prop set to true, this changes several button behaviours so that the icon will follow/unfollow while the label will navigate to the topic itself
        dualPurpose: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hoverActive: false
        };
    },

    computed: {
        buttonTint() {
            // Set an explicit tint first (least expensive)
            if (this.tint) {
                return this.tint;
            }
            // Set a tint from the parent if set
            if (this.topic.parent && this.topic.parent.color) {
                return this.topic.parent.color;
            }
            // Most expensive fallback, look up the channel in the store and set its color
            const channel = this.$store.getters['topics/channelByTopicId'](this.topic.id);
            return channel?.color;
        }
    },

    methods: {
        updateTopic(newValue) {
            const topicClone = { ...this.topic };
            topicClone.userIsFollowing = newValue;
            if (newValue === true) {
                this.$emit('followed', topicClone);
            } else {
                this.$emit('unfollowed', topicClone);
            }

            this.$store.commit('topics/updateTopicById', topicClone);
        },

        getButtonListeners(followOrUnfollowTopic) {
            if (this.dualPurpose) {
                return;
            }

            return {
                click: followOrUnfollowTopic,
                mouseout: () => { this.hoverActive = false; },
                mouseover: () => { this.hoverActive = true; }
            };
        }
    }
};
</script>

<style lang="less" src="./follow-topic-button.less" />
