<template>
    <asc-icon :svg-attrs="{ width: 24, height: 24 }" viewbox="0 0 24 24">
        <template v-slot:title>News</template>
        <template v-slot:default>
            <path d="M12.5556 9.0814L14.5926 1L2 14.407H11.6296L9.22222 23L22 9.0814H12.5556Z" fill="#180032" />
        </template>
    </asc-icon>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon }
};
</script>
