<template>
    <div class="layout">
        <asc-toasts />

        <menu-overlay :is-visible="isMenuOpen" />

        <slot name="navigation">
            <navigation-left />

            <asc-searchbar />

            <navigation-right />
        </slot>

        <div class="layout__main">
            <slot />
        </div>

        <asc-footer v-if="showFooter" />

        <asc-add-update-modal />
        <asc-edit-update-modal />
        <asc-add-organisation-modal />
        <asc-follow-topics-modal />
        <asc-full-page-loader />

        <client-only>
            <asc-cookie-modal />
        </client-only>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AscSearchbar from '~/patterns/molecules/searchbar/searchbar.vue';
import AscToasts from '~/patterns/molecules/toasts/toasts.vue';

import NavigationLeft from '~/patterns/organisms/navigation/navigation-left.vue';
import NavigationRight from '~/patterns/organisms/navigation/navigation-right.vue';
import MenuOverlay from '~/patterns/organisms/menu-overlay/menu-overlay.vue';
import AscCookieModal from '~/patterns/organisms/cookie-consent/cookie-modal.vue';
import AscFooter from '~/patterns/organisms/footer/footer.vue';
import AscAddUpdateModal from '~/patterns/organisms/modal/presets/add-update/add-update.vue';
import AscEditUpdateModal from '~/patterns/organisms/modal/presets/edit-update/edit-update.vue';
import AscAddOrganisationModal from '~/patterns/organisms/modal/presets/add-organisation/add-organisation.vue';
import AscFollowTopicsModal from '~/patterns/organisms/modal/presets/follow-topics/follow-topics.vue';
import AscFullPageLoader from '~/patterns/molecules/page-loader/page-loader.vue';

/**
 * This layout is to be loaded as a child component of an actual nuxt layout, allowing for re-use across nuxt layouts.
 */
export default {
    name: 'CoreLayout',

    components: {
        NavigationLeft,
        AscSearchbar,
        NavigationRight,
        AscFooter,
        MenuOverlay,
        AscToasts,
        AscAddUpdateModal,
        AscEditUpdateModal,
        AscFollowTopicsModal,
        AscCookieModal,
        AscAddOrganisationModal,
        AscFullPageLoader
    },

    props: {
        showFooter: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters({
            isMenuOpen: 'ui/isMenuOpen'
        })
    }
};
</script>

<style lang="less">
.layout {
    // Make sure the footer is always sticking to the bottom of the page.
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.layout__main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    & > * {
        flex: auto;
    }
}
</style>
