<template>
    <asc-icon :svg-attrs="{ width: 24, height: 24 }" viewbox="0 0 24 24">
        <template v-slot:title>Opportunity</template>
        <template v-slot:default>
            <path d="M12 1L1 9.86814L5.202 23.011H18.798L23 9.86814L12 1ZM16.114 19.0879L12 16.1099L7.886 19.0879L9.459 14.2637L5.455 11.2747H10.427L12 6.45055L13.573 11.2747H18.545L14.541 14.2528L16.114 19.0879Z" fill="#180032" />
        </template>
    </asc-icon>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon }
};
</script>
