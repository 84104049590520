<template>
    <nav class="navigation-right" :data-collapsed="isCollapsed" :data-search-open="isSearchOpen" :data-auth="$auth.loggedIn">
        <template v-if="$auth.loggedIn">
            <asc-button
                class="dn-button--clean navigation-right__item navigation-right__icon"
                title="Add update"
                @click="$store.dispatch('modal/open', 'add-update')"
            >
                <asc-icon icon="plus" class="dn-icon--square" />
            </asc-button>

            <nuxt-link to="/profile" class="navigation-right__item">
                <asc-avatar
                    :link-to-user="false"
                    :user="$auth.user"
                    class="avatar--tiny navigation-right__avatar"
                />
            </nuxt-link>
        </template>

        <template v-else>
            <asc-button
                element="nuxt-link"
                to="/login"
                title="Login"
                class="dn-button--link dn-button--small hidden-xxs hidden-xs navigation-right__item"
            >
                Login
            </asc-button>

            <asc-button
                element="nuxt-link"
                to="/sign-up"
                title="Register"
                class="dn-button--primary dn-button--small hidden-xxs hidden-xs navigation-right__item"
            >
                Join
            </asc-button>
        </template>

        <asc-button
            class="dn-button--clean navigation-right__item navigation-right__icon"
            title="Search"
            @click="$store.dispatch('ui/toggleSearch')"
        >
            <asc-icon icon="search" class="dn-icon--square" />
        </asc-button>

        <asc-button
            class="dn-button--clean navigation-right__item navigation-right__hamburger"
            :title="menuOpen ? 'Close menu' : 'Open menu'"
            @click="toggleMenu"
        >
            <asc-hamburger
                :data-toggled="menuOpen"
            />
        </asc-button>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import AscHamburger from '~/patterns/atoms/hamburger/hamburger.vue';
import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import AscAvatar from '~/patterns/atoms/avatar/avatar.vue';

export default {
    components: {
        AscHamburger,
        AscIcon,
        AscButton,
        AscAvatar
    },

    computed: {
        ...mapGetters({
            menuOpen: 'ui/isMenuOpen'
        }),

        isSearchOpen() {
            return this.$store.getters['ui/isSearchOpen'](this.$route);
        },

        isCollapsed() {
            // @todo make this fancier (08-2020)
            // if (this.$store.getters['ui/heroInView']) {
            //     return false;
            // }

            return this.$store.getters['ui/isNavigationCollapsed'];
        }
    },

    methods: {
        toggleMenu() {
            this.$store.dispatch('ui/toggleMenu');
        }
    }
};
</script>

<style lang="less" src="./navigation-right.less"></style>
