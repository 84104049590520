export const TYPE_NEWS = 'news';
export const TYPE_EVENT = 'event';
export const TYPE_REQUEST = 'request';
export const TYPE_OPPORTUNITY = 'opportunity';
export const TYPE_PROJECT = 'project';
export const TYPE_EXPERIENCE = 'experience';

export default [
    TYPE_NEWS,
    TYPE_EVENT,
    TYPE_REQUEST,
    TYPE_OPPORTUNITY,
    TYPE_PROJECT,
    TYPE_EXPERIENCE
];
