/**
 * App-wide events and vars for matomo
 */
export default function({ $matomo, $auth, $cookieConsent }) {
    $matomo.requireCookieConsent();

    const hasConsent = $cookieConsent.hasConsent('analytical');

    if (!hasConsent) {
        $matomo.forgetCookieConsentGiven();
        $matomo.disableCookies();
    } else {
        $matomo.setCookieConsentGiven();
    }

    if ($auth.loggedIn) {
        // As user ID can be backtraced to a person, only store it with given consent.
        if (hasConsent) {
            $matomo.setUserId($auth.user.id);
        } else {
            $matomo.resetUserId();
        }
        $matomo.setCustomDimension(1, 'yes'); // IsLoggedIn dimension
    } else {
        $matomo.resetUserId();
        $matomo.setCustomDimension(1, 'no'); // IsLoggedIn dimension
    }

    $matomo.enableLinkTracking();
}
