<template>
    <nav v-if="menuItems && menuItems.length" class="page-nav">
        <ul>
            <li v-for="(item, index) in menuItems" :key="`item_${index}`">
                <nuxt-link :to="item.to">
                    {{ item.label }}
                    <asc-icon icon="arrow-right" />
                </nuxt-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import pagesForMenu from '~/graphql/queries/page/pagesForMenu.graphql';

export default {
    components: {
        AscIcon
    },

    props: {
        routes: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            defaultItems: [
                {
                    to: '/about',
                    label: 'About us'
                }
            ]
        };
    },

    async fetch() {
        try {
            const { data } = await this.$gql.executeQuery(
                pagesForMenu,
                { in_menu: true }
            );

            data.pages.forEach((page) => {
                const menuItem = {
                    to: '/' + page.slug,
                    label: page.title
                };

                this.defaultItems.push(menuItem);
            });
        } catch (e) {
            // Handle error
        }
    },

    computed: {
        menuItems() {
            if (this.routes && this.routes.length) {
                return this.routes;
            }

            return this.defaultItems;
        }
    }
};
</script>

<style lang="less" src="./page-nav.less" scoped></style>
