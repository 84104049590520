import { render, staticRenderFns } from "./page-loader.vue?vue&type=template&id=4182b077&scoped=true"
import script from "./page-loader.vue?vue&type=script&lang=js"
export * from "./page-loader.vue?vue&type=script&lang=js"
import style0 from "./page-loader.less?vue&type=style&index=0&id=4182b077&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4182b077",
  null
  
)

export default component.exports