<template>
    <div class="dn-toast">
        <div class="dn-toast__body">
            <slot><p v-html="message" /></slot>

            <div class="dn-toast__dismiss">
                <asc-button class="dn-button--icon" @click="removeToast">
                    <asc-icon icon="cross" />
                </asc-button>
            </div>
        </div>
    </div>
</template>

<script>
'use strict';
import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscButton,
        AscIcon
    },

    props: {
        message: {
            type: String,
            required: true
        },
        interval: {
            type: Number,
            default: 6000
        },
        id: {
            type: [String, Number],
            default: null,
            required: true
        }
    },

    created() {
        this.timeout = setTimeout(() => this.removeToast(), this.interval);
    },

    methods: {
        removeToast() {
            this.$emit('removeToast', this.id);
        }
    }
};
</script>

<style lang="less" src="./toast.less" scoped></style>
