<template>
    <core-layout>
        <nuxt />
    </core-layout>
</template>

<script>
import CoreLayout from '~/layouts/_core.vue';

export default {
    components: {
        CoreLayout
    }
};
</script>
