/**
 * Given a GraphQL error object (https://spec.graphql.org/June2018/#sec-Errors), display them in a human-friendly manner.
 */
export default function formatGqlErrors(errors) {
    if (!errors) {
        return false;
    }

    const messages = [];

    // Loop trough arrays of errors
    if (Array.isArray(errors) && errors.length) {
        errors.forEach((error) => {
            // If the error contains debug data, log it to the console.
            if (error.debugMessage) {
                // eslint-disable-next-line no-console
                console.error(error);
            }

            if (error.extensions.validation) { // If the error contains one or more (Laravel) validation errors, return those
                return Object.entries(error.extensions.validation).forEach((entry) => {
                    const field = `${entry[0].replace('input.', '')}`;

                    if (entry[1].length) {
                        entry[1].forEach((message) => {
                            messages.push({
                                field,
                                text: message,
                                type: 'error'
                            });
                        });
                    }
                });
            } else if (error.extensions.reason) { // If the error contains a reason, display that
                messages.push({
                    key: error.extensions.category,
                    text: error.extensions.reason,
                    type: 'error'
                });
            } else if (error.message && error.message.length) { // Fallback to the generic error message from the backend, if present
                messages.push({
                    key: 'fallback',
                    text: error.message,
                    type: 'error'
                });
            }
        });
        return messages;
    }

    // If an error is present but not an array or string, display a fallback message.
    messages.push({
        key: 'fallback',
        text: 'Something went wrong. Please try again later.',
        type: 'error'
    });

    return messages;
}
