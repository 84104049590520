<template>
    <core-layout>
        <nuxt />

        <profile-section
            v-if="routes && routes.length"
            class="profile-section--bordered"
            data-background="gray"
        >
            <template v-slot:title>Settings</template>

            <template v-slot:default>
                <slot name="navigation">
                    <asc-page-nav :routes="routes" />
                </slot>
            </template>
        </profile-section>
    </core-layout>
</template>

<script>
import CoreLayout from '~/layouts/_core.vue';
import { ProfileSection } from '~/dn-modules/profile';
import AscPageNav from '~/patterns/molecules/page-nav/page-nav.vue';

export default {
    components: {
        CoreLayout,
        ProfileSection,
        AscPageNav
    },

    data() {
        return {
            routes: [
                {
                    to: '/profile/details',
                    label: 'Profile details'
                },
                {
                    to: '/profile/my-interests',
                    label: 'My interests'
                },
                {
                    to: '/profile/security',
                    label: 'Account security'
                },
                {
                    to: '/profile/preferences',
                    label: 'Notification preferences'
                }
            ]
        };
    }
};
</script>
