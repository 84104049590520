<template>
    <div class="footer">
        <div class="footer__container">
            <div class="footer__body">
                <div class="footer__title">
                    <h2 class="sr-only">Shaping better cities <br>as an open collective</h2>

                    <img
                        src="~assets/img/illustrations/tagline-footer.svg"
                        alt="Shaping better cities as an open collective"
                        class="footer__tagline-desktop"
                    >

                    <img
                        src="~assets/img/illustrations/tagline-footer_mobile.svg"
                        alt="Shaping better cities as an open collective"
                        class="footer__tagline-mobile"
                    >
                </div>

                <ul v-if="primaryRoutes && primaryRoutes.length">
                    <li v-for="(route, index) in primaryRoutes" :key="`main_route_${index}`">
                        <nuxt-link :to="route.to">{{ route.label }}</nuxt-link>
                    </li>
                </ul>

                <ul v-if="secondaryRoutes && secondaryRoutes.length">
                    <li><a href="mailto:info@amsterdamsmartcity.com">Contact us</a></li>
                    <li v-for="(route, index) in secondaryRoutes" :key="`sub_route_${index}`">
                        <nuxt-link :to="route.to">{{ route.label }}</nuxt-link>
                    </li>
                </ul>
            </div>

            <nav class="footer__info">
                <ul>
                    <li>
                        <a href="#" @click.prevent="$store.dispatch('modal/open', 'cookie-modal')">
                            Privacy settings
                        </a>
                    </li>
                    <li><nuxt-link to="/privacy">Privacy statement</nuxt-link></li>
                    <li>© Amsterdam Smart City</li>
                    <li>Made by <a href="https://www.digitalnatives.nl" target="_blank" rel="noopener">Digital Natives</a></li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            primaryRoutes: 'ui/primaryMenuRoutes',
            secondaryRoutes: 'ui/secondaryMenuRoutes'
        })
    }
};
</script>

<style src="./footer.less" lang="less" scoped></style>
