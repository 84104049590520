import stringifyGqlQuery from '~/graphql/helpers/stringified-query';
import updateUserLastActiveMutation from '~/graphql/mutations/user/updateLastActive.graphql';

export default function({ $auth, $axios }) {
    if (!$auth.loggedIn) {
        return;
    }

    setInterval(async() => {
        const headers = {},
            results = await $axios.post('', {
                query: stringifyGqlQuery(updateUserLastActiveMutation)
            }, { headers });

        return results.data;
    }, 60000);
}
