<template>
    <div class="markdown-editor">
        <!-- Workaround for client-only placeholder (which doesn't work) -->
        <transition name="fade">
            <div v-if="!isMounted" key="loading" class="markdown-editor__loading">
                <asc-loader class="loader--centered" />
            </div>
        </transition>

        <div class="markdown-editor__editor">
            <client-only>
                <async-tui-editor
                    ref="editor"
                    :initial-value="value"
                    :options="options"
                    preview-style="tab"
                    initial-edit-type="wysiwyg"
                    @focus="onFocus"
                    @blur="onBlur"
                    @change="onChange"
                    @mounted="onEditorMount"
                />
            </client-only>
        </div>
    </div>
</template>

<script>
import DOMPurify from 'dompurify';
import AscLoader from '~/patterns/atoms/loader/loader.vue';

export default {
    components: {
        AscLoader
    },

    props: {
        value: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            content: '',
            allowChangeEvent: false,
            isMounted: false,
            options: {
                toolbarItems: [
                    [
                        'heading',
                        'bold',
                        'italic',
                        'link'
                    ]
                ],
                usageStatistics: false,
                hideModeSwitch: true,
                customHTMLSanitizer(html) {
                    const sanitizedHtml = DOMPurify.sanitize(html, {
                        ALLOWED_TAGS: ['h2', 'h3', 'h4', 'p', 'br', 'em', 'strong', 'b', 'i', 'a', 'ul', 'ol', 'li', 'img'],
                        ALLOWED_ATTR: ['href', 'title', 'alt', 'src', 'rel']
                    });

                    return sanitizedHtml;
                }
            }
        };
    },

    methods: {
        onChange() {
            this.content = this.getMarkdown();

            if (this.allowChangeEvent) {
                this.$emit('input', this.content);
                this.$emit('change', this.content);
            }
        },

        onBlur() {
            this.$emit('blur');
        },

        onFocus() {
            this.$emit('focus');
        },

        onEditorMount() {
            /**
             * Without the following code, the change event is triggered
             * directly after mounting. This means that vee-validate
             * will instantly throw an error if no content is present yet.
             *
             * This is prevented by waiting a brief period of time before
             * allowing the change event to be fired.
             *
             * This is especially useful for create forms (when there is no input yet).
             */
            if (!this.value) {
                setTimeout(() => {
                    this.allowChangeEvent = true;
                }, 2000);
            } else {
                this.allowChangeEvent = true;
            }

            this.isMounted = true;
        },

        getMarkdown() {
            let markdown = this.$refs.editor.invoke('getMarkdown');

            // Turn any unwanted <br> tags into linebreaks
            // The editor tends to add these when adding more than a few line breaks.
            markdown = markdown.replace(/<br>/g, '\n');

            return markdown;
        }
    }
};
</script>

<style src="./markdown-editor.less" lang="less"></style>
