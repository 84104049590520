/**
 * Various listeners for @nuxtjs/auth events
 */
export default function({ $auth, store, redirect }) {
    $auth.onRedirect((to, from) => {
        if (from !== '/login') {
            return;
        }

        const activeRouteBeforeLogin = store.getters['ui/activeRouteBeforeLogin'],
            ignoredRoutes = ['login', 'logout', 'forgot-password', 'sign-up', 'set-password'];

        // Redirect to the previously visited page
        // Note: there are some exceptions to this rule (below).
        if (
            activeRouteBeforeLogin &&
            activeRouteBeforeLogin.name &&
            !ignoredRoutes.includes(activeRouteBeforeLogin.name)
        ) {
            return redirect(activeRouteBeforeLogin);
        }

        // If there was not previous route (or it was ignored), redirect to home.
        return redirect('/');
    });
}
