<template>
    <div class="dn-toasts">
        <transition-group name="dn-toast" tag="div">
            <div
                v-for="(message, index) in messages"
                :key="message.id"
                :style="{
                    zIndex: index + 1,
                    bottom: `${messages.length - index - 1}rem`
                }"
                class="dn-toasts__toast-wrapper"
            >
                <toast
                    :id="message.id"
                    :message="message.message"
                    :interval="message.duration"
                    :data-status="message.status"
                    @removeToast="removeMessage"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Toast from '~/patterns/atoms/toast/toast.vue';

export default {
    components: {
        Toast
    },

    computed: {
        ...mapGetters({
            messages: 'toasts/messages'
        })
    },

    methods: {
        removeMessage(messageId) {
            this.$store.commit('toasts/removeMessage', messageId);
        }
    }
};
</script>

<style lang="less" src="./toasts.less" scoped></style>
