<script>
import formatGqlError from '~/helpers/formatGqlError.js';
import followMutation from '~/graphql/mutations/follow/follow.graphql';
import unfollowMutation from '~/graphql/mutations/follow/unfollow.graphql';

/**
 * This is a renderless component which acts as a wrapper around another element (like a button) to follow / unfollow an entry.
 * You could further wrap this in another component to standardize the various props to pass. (Eg. a follow-topic-button)
 *
 * Example usage:
 *
 * ```
 * <template>
 *      <follow :following.sync="myFollowingBoolean" following-type="TOPIC" :following-id="1" v-slot="{ clickHandler : scopedClickHandler }">
 *          <asc-button @click="scopedClickHandler">
 *              <template v-if="myFollowingBoolean">Unfollow</template>
 *              <template v-else>Follow something</template>
 *          </asc-button>
 *      </follow>
 * </template>
 * ```
 */
export default {
    props: {
        following: {
            type: Boolean,
            required: true,
            default: false
        },
        followingType: {
            type: String,
            required: true,
            default: 'topic'
        },
        followingId: {
            type: Number,
            required: true,
            default: null
        },
        followingName: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            state: null,
            error: null,
            matomoEventValue: null
        };
    },

    methods: {
        async clickHandler() {
            // Don't do anything while handling some other request
            if (this.state === 'loading') {
                return;
            }

            if (!this.$auth.user) {
                this.$router.push({ path: '/sign-up', query: { redirect: this.$route.path } });
                this.$store.commit('toasts/addMessage', {
                    message: 'Please log-in or register to follow an item.'
                });
                return;
            }

            this.state = 'loading';
            try {
                this.matomoEventValue = this.followingName;
                if (this.followingName === null) {
                    this.matomoEventValue = this.followingId;
                }

                if (!this.following) {
                    await this.$gql.executeQuery(followMutation, { type: this.followingType, id: this.followingId });
                    this.$matomo.trackEvent(this.followingType, 'follow', this.matomoEventValue);
                } else {
                    await this.$gql.executeQuery(unfollowMutation, { type: this.followingType, id: this.followingId });
                    this.$matomo.trackEvent(this.followingType, 'unfollow', this.matomoEventValue);
                }

                this.state = 'success';
            } catch (e) {
                const messages = formatGqlError(e);

                messages.forEach((message) => {
                    this.$store.commit('toasts/addMessage', {
                        message: message.text,
                        status: 'error'
                    });
                });
                this.error = e;
                this.state = 'error';
            }

            // Emit two-way-binding
            this.$emit('update:following', !this.following);
        }
    },

    render() {
        return this.$scopedSlots.default({
            clickHandler: this.clickHandler,
            state: this.state,
            error: this.error
        });
    }
};
</script>
