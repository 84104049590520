import { render, staticRenderFns } from "./page-nav.vue?vue&type=template&id=21e54376&scoped=true"
import script from "./page-nav.vue?vue&type=script&lang=js"
export * from "./page-nav.vue?vue&type=script&lang=js"
import style0 from "./page-nav.less?vue&type=style&index=0&id=21e54376&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e54376",
  null
  
)

export default component.exports