<template>
    <section class="profile-section">
        <div class="profile-section__container">
            <h2 v-if="!!$slots.title || title" class="profile-section__title">
                <slot name="title">{{ title }}</slot>
            </h2>

            <div v-if="!!$slots.intro" class="profile-section__intro">
                <slot name="intro" />
            </div>

            <div v-if="!!$slots.default" class="profile-section__body">
                <slot />
            </div>

            <div v-if="!!$slots.cta" class="profile-section__cta">
                <slot name="cta" />
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="less">
.profile-section {
    margin: var(--spacing-3xl) 0;
}

.profile-section__container {
    .content-container();
}

.profile-section__title {
    margin-bottom: var(--spacing-lg);
}

.profile-section__intro {
    .typography-text();
    max-width: 36rem;
    margin-bottom: var(--spacing-xl);
    color: var(--color-black-50);
}

.profile-section__cta {
    margin-top: var(--spacing-lg);
}

.profile-section--bordered {
    margin: 0;
    padding: var(--spacing-3xl) 0;
    border-top: 2px solid var(--color-black-20);
}

.profile-section[data-background='gray'] {
    background-color: var(--color-black-10);
}
</style>
