<template>
    <div
        v-if="user"
        class="my-profile"
    >
        <div class="my-profile__body">
            <slot>
                <h2 v-if="user.name">{{ user.name }}</h2>

                <asc-label v-if="user.email">{{ user.email }}</asc-label>

                <nuxt-link class="my-profile__action" :to="`/members/${user.id}`">View public profile</nuxt-link>
                <nuxt-link class="my-profile__action" to="/logout">Log out</nuxt-link>
            </slot>
        </div>

        <div class="my-profile__avatar">
            <slot name="avatar">
                <asc-avatar class="avatar--large" :user="user" />
            </slot>
        </div>
    </div>
</template>

<script>
import AscLabel from '~/patterns/atoms/label/label.vue';
import AscAvatar from '~/patterns/atoms/avatar/avatar.vue';

export default {
    components: { AscLabel, AscAvatar },

    props: {
        user: {
            type: Object,
            default: null
        }
    }
};
</script>

<style lang="less">
.my-profile {
    display: flex;
    flex-direction: column-reverse;

    @media @q-md-min {
        flex-direction: row;
    }

}

.my-profile__body {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    row-gap: var(--spacing-sm);

    @media @q-md-min {
        flex: 1 1 auto;
    }
}

.my-profile__avatar {
    align-self: center;
    margin-bottom: var(--spacing-xl);

    @media @q-md-min {
        align-self: flex-end;
        margin-bottom: 0;
        margin-left: var(--spacing-lg);
    }
}

.my-profile__action {
    .typography-label();
    margin-top: .5rem;

    cursor: pointer;

    &:hover, &:focus {
        text-decoration: underline;
    }
}
</style>
