<script>
import DnIcon from '@digitalnatives/icon';
import iconComponents from './icons';

export default {
    // The iconComponents below will lazily load *all* svg files in /assets/img/icons into this component
    // Use them by passing the correct icon prop to this component.
    components: iconComponents,
    extends: DnIcon
};
</script>

<style lang="less">
.dn-icon--inline {
    /* stylelint-disable-next-line declaration-no-important */
    display: inline-block !important;
}
</style>
