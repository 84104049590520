export default function(context) {
    const baseURL = context.$config.baseURL,
        // Get route
        route = context.route,

        // Colect params after "/"
        params = route.path.split('/');

    // Remove first "/" entry from array
    params.shift();

    let prefix = params[0],
        redirectPath = '',
        slug = '',
        slugAddon = '';

    // If params has more then 1 entry and is not user prefix, set first parameter as slug
    if (params.length > 1 && prefix !== 'users') {
        slug = params[1];
    }

    // If params has more then 2 entries in with prefix set an additional slug
    if (params.length > 2 && prefix === 'themes') {
        slugAddon = params[2];
    }

    // If prefix is "p" make slug the prefix and remove set slug value
    if (prefix === 'p') {
        prefix = slug;
        slug = '';
    }

    // All redirects
    const redirects = [
        // To Update page
        {
            old: 'posts',
            new: 'news',
            type: 'update'
        },
        {
            old: 'requests',
            new: 'request',
            type: 'update'
        },
        {
            old: 'events',
            new: 'event',
            type: 'update'
        },
        {
            old: 'projects',
            new: 'project',
            type: 'update'
        },
        {
            old: 'visit',
            new: 'experience',
            type: 'update'
        },
        {
            old: 'items',
            new: 'news',
            type: 'update'
        },

        // To Community page
        {
            old: 'network',
            new: 'organisations',
            type: 'community'
        },
        {
            old: 'users',
            new: 'members',
            type: 'community'
        },
        {
            old: 'network?tags=program-partner',
            new: 'partners',
            type: 'community'
        },

        // To Channel page
        {
            old: 'themes',
            new: 'channel',
            type: 'channel'
        },

        // To static page
        {
            old: 'contact',
            new: 'team',
            type: 'static'
        },
        {
            old: 'faq',
            new: 'about',
            type: 'static'
        },
        {
            old: 'guidelines',
            new: 'community-and-guidelines',
            type: 'static'
        },
        {
            old: 'platform-guidelines',
            new: 'community-and-guidelines',
            type: 'static'
        },
        {
            old: 'vacancy-community-and-program-officer',
            new: 'team',
            type: 'static'
        },
        {
            old: 'keyvalues',
            new: 'about',
            type: 'static'
        },
        {
            old: 'from-talking-to-doing',
            new: 'about',
            type: 'static'
        },
        {
            old: 'terms',
            new: 'community-and-guidelines',
            type: 'static'
        },
        {
            old: 'circularamsterdam',
            new: 'channel/circular-city',
            type: 'static'
        },
        {
            old: 'city-zen',
            new: 'channel/energy',
            type: 'static'
        },
        {
            old: 'products',
            new: 'updates/project',
            type: 'static'
        },
        {
            old: 'settings',
            new: 'profile/details',
            type: 'static'
        },
        {
            old: 'welcome',
            new: 'onboarding',
            type: 'static'
        },
        {
            old: 'academy',
            new: 'channel/smartcityacademy',
            type: 'static'
        },
        {
            old: 'international-projects',
            new: 'updates/project',
            type: 'static'
        },
        {
            old: 'organisations/chief-technology-office',
            new: 'organisations/digitalization-innovation',
            type: 'static'
        },

        // To home page
        {
            old: 'praatmee',
            new: '',
            type: 'home'
        },
        {
            old: 'goglobal',
            new: '',
            type: 'home'
        },
        {
            old: 'vehicle-to-grid',
            new: '',
            type: 'home'
        },
        {
            old: 'monthly-poll',
            new: '',
            type: 'home'
        },
        {
            old: 'help-us-improve',
            new: '',
            type: 'home'
        },
        {
            old: 'vehicle-to-grid-eng',
            new: '',
            type: 'home'
        },
        {
            old: 'local-elections',
            new: '',
            type: 'home'
        },
        {
            old: 'civic-tech',
            new: '',
            type: 'home'
        },
        {
            old: 'themes',
            new: '',
            type: 'home'
        }
    ];

    redirects.forEach((redirect) => {
        // Redirect all static pages directly from the old to new URL (remove preceding and trailing slash from old)
        if (route.path?.replace(/^\/|\/$/g, '') === redirect.old && redirect.type === 'static') {
            redirectPath = '/' + redirect.new;
            return;
        }

        // Match the request prefix (first param) with the first parameter of an "old" URL to match for redirection
        if (prefix === redirect.old.split('/')[0]) {
            // For redirects TO updates, prefix everything with 'updates' and the new update type
            if (redirect.type === 'update') {
                redirectPath = '/updates/' + redirect.new;

                // If the request contains a probably slug, add it as a suffix
                if (slug) {
                    redirectPath = redirectPath + '/' + slug;
                }

                // Fallback redirect to a common channel
                if (redirect.new === 'item' || slug === '') {
                    redirectPath = '/channel/smartcityacademy';
                }

                return;
            }

            // Redirect all community URLs to /new-path, appending a slug if set
            if (redirect.type === 'community') {
                redirectPath = '/' + redirect.new;

                if (slug) {
                    redirectPath = redirectPath + '/' + slug;
                }

                return;
            }

            // Redirect home and channel pages to '/', 'channel/slug' optionally appending a second slug
            if (redirect.type === 'home' || redirect.type === 'channel') {
                redirectPath = '';

                if (slug) {
                    redirectPath = '/channel/' + slug;

                    // Governance & Educucation won't be on the new platform
                    // so should redirect to Smart City Academy
                    if (slug === 'governance-education') {
                        redirectPath = '/channel/smartcityacademy';
                    }
                }
                if (slugAddon) {
                    redirectPath = redirectPath + '/' + slugAddon;
                }
            }
        }
    });

    if (redirectPath) {
        return context.redirect(baseURL + redirectPath);
    }
}
