<template>
    <core-layout
        :show-footer="false"
        class="layout--auth"
    >
        <template v-slot:navigation>
            <navigation-left :can-collapse="false" :allow-back-button="false" />
            <asc-searchbar />
            <navigation-right />
        </template>

        <template v-slot:default>
            <div class="layout__auth-container">
                <auth-panel>
                    <nuxt keep-alive />
                </auth-panel>
            </div>
        </template>
    </core-layout>
</template>

<script>
import { AuthPanel } from '~/dn-modules/auth';
import CoreLayout from '~/layouts/_core.vue';
import NavigationLeft from '~/patterns/organisms/navigation/navigation-left.vue';
import NavigationRight from '~/patterns/organisms/navigation/navigation-right.vue';
import AscSearchbar from '~/patterns/molecules/searchbar/searchbar.vue';

export default {
    components: {
        CoreLayout,
        NavigationLeft,
        AscSearchbar,
        NavigationRight,
        AuthPanel
    }
};
</script>

<style lang="less">
.layout.layout--auth {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background: #000285 url('~assets/img/backgrounds/auth-bg.jpg'); // @TODO: Make responsive & smaller where possible
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media @q-sm-min {
        align-items: center;
    }
}

.layout__auth-container {
    display: inline-flex;
    align-items: center;

    @media @q-sm-max {
        .page-container();
        width: 100%;
        margin: 10rem 0;
    }

    @media @q-sm-min {
        width: 25rem;
        margin: 6.5rem 0;
    }
}

/* page */
.auth-enter-active, .auth-leave-active {
    transition: opacity 250ms 0ms ease-out;
}

.auth-enter, .auth-leave-to {
    opacity: 0;
}
</style>
