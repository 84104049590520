
<template>
    <div class="auth-loader">
        <slot>Loading...</slot>
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less">
.auth-loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);

    background: rgba(255, 255, 255, .95);
    text-align: center;
    user-select: none;
}
</style>
