// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/placeholders/avatar.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:cover;border-radius:var(--borderRadius-full);display:block;height:3rem;overflow:hidden;width:3rem}.avatar img{height:100%;-o-object-fit:cover;object-fit:cover;overflow:hidden;width:100%}.avatar--large{height:5rem;width:5rem}@media screen and (min-width:769px){.avatar--large{height:6rem;width:6rem}}.avatar--small{height:2.5rem;width:2.5rem}.avatar--tiny{height:1.5rem;width:1.5rem}@media screen and (min-width:769px){.avatar--tiny{height:2rem;width:2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
