export default {
    channels: ({ channels }) => channels,

    channelById: ({ channels }) => id => channels ? channels.find(channel => channel.id === id) : null,

    channelBySlug: ({ channels }) => slug => channels ? channels.find(channel => channel.slug === slug) : null,

    channelByTopicId: ({ channels }) => (topicId) => {
        if (channels) {
            return channels.find(({ children }) => {
                return children.findIndex(({ id }) => id === topicId) !== -1;
            });
        }
        return null;
    },

    topics: ({ channels }) => {
        if (!channels) {
            return null;
        }
        let topics = [];

        channels.forEach((channel) => {
            if (channel.children) {
                topics = topics.concat(channel.children);
            }
        });

        return topics;
    },

    topicById: (state, getters) => (id) => {
        const topics = getters.topics;

        if (!topics) {
            return null;
        }

        return topics.find(topic => topic.id === id);
    },

    followingTopics: (state, getters) => {
        const topics = getters.topics;

        if (!topics) {
            return null;
        }

        return topics.filter(topic => topic.userIsFollowing);
    }
};
