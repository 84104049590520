export default {
    toggleMenu({ commit, getters }) {
        commit('isMenuOpen', !getters.isMenuOpen);
    },

    toggleSearch({ commit, getters }) {
        commit('isSearchOpen', !getters.isSearchOpen());
    },

    hideSearch({ commit, getters }) {
        commit('isSearchOpen', false);
    },

    closeMenu({ commit }) {
        commit('isMenuOpen', false);
    },

    openMenu({ commit }) {
        commit('isMenuOpen', true);
    }
};
