<template>
    <div class="topics-select">
        <asc-accordion :items="channels">
            <template v-slot:titleSuffix="{ item: channel }">
                {{ channel.children.length }} topics
                <template v-if="childrenSelectedCount(channel)">
                    ({{ childrenSelectedCount(channel) }} selected)
                </template>
            </template>

            <template v-slot:default="{ item: channel }">
                <asc-topics-grid>
                    <asc-topic-button
                        v-for="topic in channel.children"
                        :key="topic.id"
                        :topic="topic"
                        :is-selected="isTopicSelected(topic.id)"
                        :data-disabled="maxCount !== null && selectedCount >= maxCount && !isTopicSelected(topic.id)"
                        :tint="channel.color"
                        @click="toggleTopicSelect(topic.id)"
                    />
                </asc-topics-grid>
            </template>
        </asc-accordion>
    </div>
</template>

<script>
import { intersection } from 'lodash';
import { mapGetters } from 'vuex';

import AscAccordion from '~/patterns/molecules/accordion/accordion.vue';
import AscTopicsGrid from '~/patterns/molecules/topics-grid/topics-grid.vue';
import AscTopicButton from '~/patterns/atoms/topic-button/topic-button.vue';

export default {
    components: {
        AscAccordion,
        AscTopicsGrid,
        AscTopicButton
    },

    props: {
        maxCount: {
            type: Number,
            default: null
        },
        value: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            channels: 'topics/channels'
        }),
        selectedCount() {
            return this.value.length;
        }
    },

    methods: {
        /**
         * Toggle a topic's selected state by providing it's ID.
         */
        toggleTopicSelect(topicId) {
            if (this.isTopicSelected(topicId)) {
                this.$store.commit('toasts/removeMessage', 'maxTopicsError');
                return this.deselectTopic(topicId);
            } else if (this.maxCount && this.selectedCount >= this.maxCount) {
                this.$store.commit('toasts/addMessage', {
                    message: `You’ve reached the limit of ${this.maxCount} topics. Uncheck a topic first if you’d like to add another one.`,
                    status: 'error',
                    id: 'maxTopicsError'
                });
            } else {
                this.selectTopic(Number(topicId));
            }
        },

        /**
         * Select a topic
         * This means it's added to the this.value array.
         */
        selectTopic(topicId) {
            if (this.isTopicSelected(topicId)) {
                return;
            }

            const appended = [...this.value];
            appended.push(Number(topicId));

            this.$emit('input', appended);
        },

        /**
         * Remove a specific topic from the this.value array.
         */
        deselectTopic(topicId) {
            if (!this.isTopicSelected(topicId)) {
                return;
            }

            const reduced = [...this.value];
            reduced.splice(this.value.indexOf(Number(topicId)), 1);

            this.$emit('input', reduced);
        },

        /**
         * Check if a specific topic is selected
         */
        isTopicSelected(topicId) {
            return this.value?.includes(Number(topicId));
        },

        /**
         * Given a channel, returns the amount of children selected
         */
        childrenSelectedCount(channel) {
            if (!this.selectedCount || !channel.children || channel.children.length === 0) {
                return 0;
            }

            const childrenIds = channel.children.map(({ id }) => Number(id));
            return intersection(this.value, childrenIds).length;
        }
    }
};
</script>
