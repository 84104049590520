<template>
    <component
        :is="avatarLink ? 'nuxt-link' : 'div'"
        :to="avatarLink ? avatarLink : null"
        class="avatar"
        :title="avatarTitle"
    >
        <img
            v-if="user && user.avatar"
            :src="user.avatar"
            :srcset="`${user.avatarTiny} 56w, ${user.avatarSmall} 80w, ${user.avatar} 96w`"
            :alt="`${user.name}'s picture`"
            loading="lazy"
        >
    </component>
</template>

<script>
export default {
    props: {
        linkToUser: {
            type: Boolean,
            default: true,
            required: false
        },

        user: {
            type: Object,
            required: false,
            default: null
        },

        to: {
            type: [String, Object],
            default: null
        }
    },

    computed: {
        avatarLink() {
            if (this.to) {
                return this.to;
            }

            if (this.user && this.linkToUser) {
                return `/members/${this.user.id}`;
            }

            return null;
        },

        avatarTitle() {
            if (!this.user) {
                return 'Anonymous';
            }

            if (this.$auth.user && this.user.id === this.$auth.user.id) {
                return 'It\'s you!';
            }

            return this.user.name;
        }
    }
};
</script>

<style lang="less" src="./avatar.less" />
