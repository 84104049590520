<template>
    <transition name="fade">
        <nav
            v-if="isVisible"
            ref="menu"
            class="menu-overlay"
            tabindex="0"
            role="dialog"
            @keydown.esc="$store.dispatch('ui/closeMenu')"
        >
            <div class="menu-overlay__overlay">
                <div class="menu-overlay__body">
                    <!-- Navigation links -->
                    <div class="menu-overlay__nav">
                        <!-- Primary menu -->
                        <ul
                            v-if="primaryRoutes && primaryRoutes.length"
                            class="menu-overlay__list-primary"
                        >
                            <li v-for="(route, index) in primaryRoutes" :key="`primary_route_${index}`">
                                <nuxt-link :to="route.to" @click.native="$store.dispatch('ui/closeMenu')">{{ route.label }}</nuxt-link>
                            </li>
                        </ul>

                        <!-- Secondary menu -->
                        <ul
                            v-if="secondaryRoutes && secondaryRoutes.length"
                            class="menu-overlay__list-secondary"
                        >
                            <li v-for="(route, index) in secondaryRoutes" :key="`secondary_route_${index}`">
                                <nuxt-link :to="route.to" @click.native="$store.dispatch('ui/closeMenu')">{{ route.label }}</nuxt-link>
                            </li>
                        </ul>
                    </div>

                    <!-- Call to actions -->
                    <div
                        v-if="!$auth.loggedIn"
                        class="menu-overlay__links"
                    >
                        <asc-button
                            element="nuxt-link"
                            to="/sign-up"
                            class="dn-button--primary dn-button--inline"
                            @click.native="$store.dispatch('ui/closeMenu')"
                        >
                            Join
                        </asc-button>

                        <asc-button
                            element="nuxt-link"
                            to="/login"
                            class="dn-button--outline dn-button--inline"
                            @click.native="$store.dispatch('ui/closeMenu')"
                        >
                            Login
                        </asc-button>
                    </div>

                    <!-- Channels -->
                    <ul v-if="channels.length" class="menu-overlay__channels">
                        <li v-for="channel in channels" :key="channel.id">
                            <asc-button
                                :tint="channel.color"
                                :to="`/channel/${channel.slug}`"
                                element="nuxt-link"
                                class="dn-button--channel"
                                @click.native="$store.dispatch('ui/closeMenu')"
                            >
                                {{ channel.title }}
                            </asc-button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="menu-overlay__backdrop" @click="$store.dispatch('ui/closeMenu')" />
        </nav>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import AscButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        AscButton
    },

    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            channels: 'topics/channels',
            primaryRoutes: 'ui/primaryMenuRoutes',
            secondaryRoutes: 'ui/secondaryMenuRoutes',
            menuOpen: 'ui/isMenuOpen'
        })
    },

    watch: {
        menuOpen() {
            if (this.menuOpen) {
                this.$nextTick(() => {
                    if (this.$refs.menu) {
                        this.$refs.menu.focus();
                    }
                });
            }
        }
    }
};
</script>

<style lang="less" src="./menu-overlay.less" />
