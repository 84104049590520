export default {
    isMenuOpen(state, isMenuOpen) {
        state.menu.isOpen = isMenuOpen;

        document.body.setAttribute('data-menu-open', isMenuOpen);
    },

    isSearchOpen(state, isSearchOpen) {
        state.isSearchOpen = isSearchOpen;
    },

    isNavigationCollapsed(state, isNavigationCollapsed) {
        state.navigation.isCollapsed = isNavigationCollapsed;
    },

    heroInView(state, heroInView) {
        state.heroInView = heroInView;
    },

    isPageLoaderVisible(state, isPageLoaderVisible) {
        state.isPageLoaderVisible = isPageLoaderVisible;
    },

    activeRouteBeforeLogin(state, activeRouteBeforeLogin) {
        state.activeRouteBeforeLogin = activeRouteBeforeLogin;
    }
};
