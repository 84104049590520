<template>
    <asc-icon :svg-attrs="{ width: 24, height: 24}" viewbox="0 0 24 24">
        <template v-slot:title>Request</template>
        <template v-slot:default>
            <g clip-path="url(#clip0)">
                <path d="M20.3684 10.9474H21.9474V5.68421H14.9579V4.48421C14.9579 3.11579 13.8526 2 12.4737 2C11.0947 2 9.98947 3.11579 9.98947 4.48421V5.68421H3V10.9474H4.68421C5.84211 10.9474 6.78947 11.8947 6.78947 13.0526C6.78947 14.2105 5.84211 15.1579 4.68421 15.1579H3V22H10.3684V20.8421C10.3684 19.6842 11.3158 18.7368 12.4737 18.7368C13.6316 18.7368 14.5789 19.6842 14.5789 20.8421V22H21.9474V15.1579H20.3684C19.2105 15.1579 18.2632 14.2105 18.2632 13.0526C18.2632 11.8947 19.2105 10.9474 20.3684 10.9474Z" fill="#180032" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="18.9474" height="20" fill="white" transform="translate(3 2)" />
                </clipPath>
            </defs>
        </template>
    </asc-icon>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon }
};
</script>
