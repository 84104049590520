<template>
    <div class="profile-view">
        <div class="profile-view__body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="less">
.profile-view {

}
</style>
