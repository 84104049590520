<template>
    <dn-button
        v-bind="$attrs"
        :style="styles"
        v-on="$listeners"
    >
        <div v-if="!!$slots.before" class="dn-button__before">
            <slot name="before" />
        </div>

        <div v-if="!!$slots.default" class="dn-button__label"><slot /></div>

        <div v-if="!!$slots.after" class="dn-button__after">
            <slot name="after" />
        </div>

        <div class="dn-button__backdrop" />
    </dn-button>
</template>

<script>
import DnButton from '@digitalnatives/button';
import { isTintValid } from '~/helpers/colors';

export default {
    components: {
        DnButton
    },

    props: {
        tint: {
            type: String,
            default: null,
            validator(value) {
                return isTintValid(value);
            }
        }
    },

    computed: {
        styles() {
            if (this.tint) {
                return `--button-background: var(--color-gradient-${this.tint}); --button-tint: var(--color-tint-${this.tint})`;
            }

            return '';
        }
    }
};
</script>

<style src="./button.less" lang="less" scoped></style>

<style lang="less">
.dn-button .dn-icon {
    display: block;
}
</style>
