'use strict';
import https from 'https';

export default function({ $axios, redirect }) {
    // Ignore https errors on local enviroments.
    if (process.env.APP_ENV === 'development') {
        const agent = new https.Agent({
            rejectUnauthorized: false
        });

        $axios.onRequest((config) => {
            config.httpsAgent = agent;
        });
    }

    // This is only relevant for GraphQL apis, which do not return a http error status,
    // but rather this will trigger a promise rejection on normal graphQL errors.
    // Make sure to handle these using try/catch or promise.catch()
    $axios.onResponse((response) => {
        if (response.data && response.data.errors && response.data.errors.length) {
            return Promise.reject(response.data.errors);
        }
    });
}
