import LocalScheme from '@nuxtjs/auth/lib/schemes/local';
import getStringifiedQuery from '~/graphql/helpers/stringified-query';
import loginQuery from '~/graphql/queries/auth/login.graphql';
import logoutQuery from '~/graphql/queries/auth/logout.graphql';
import getLoggedInUser from '~/graphql/queries/user/me.graphql';

export default class LaravelGraphqlScheme extends LocalScheme {
    /**
     * Log-in a user using a GQL mutation
     * @param {} param0
     */
    async login({ data }) {
        // Ditch any leftover local tokens before attempting to log in
        await this.$auth.reset();

        const { response, result } = await this.$auth.request({
            method: 'post',
            data: {
                query: getStringifiedQuery(loginQuery),
                variables: {
                    username: data.email,
                    password: data.password
                }
            }
        }, this.options.endpoint, true);

        if (!result.data) {
            throw new Error('Login attempt yielded no data.');
        }

        let token = result.data.login.access_token;

        if (!token || !token.length) {
            throw new Error('Failed to get a valid token.');
        }

        // Append token_type
        if (this.options.tokenType) {
            token = this.options.tokenType + ' ' + token;
        }

        // Store token
        this.$auth.setToken(this.name, token);

        // Set axios token
        this._setToken(token);

        // Store refresh token
        const refreshToken = result.data.login.refresh_token;
        if (refreshToken && refreshToken.length) {
            this.$auth.setRefreshToken(this.name, refreshToken);
        }

        await this.fetchUser();

        return response;
    }

    /**
     * Fetches a completely profile for the logged in user (based on token)
     */
    async fetchUser() {
        const { result } = await this.$auth.request({
            method: 'post',
            data: {
                query: getStringifiedQuery(getLoggedInUser)
            }
        }, this.options.endpoint, true);

        if (result.data && result.data.me) {
            this.$auth.setUser(result.data.me);
        } else {
            throw new Error('User profile data was empty.');
        }
    }

    /**
     * Invalidate the token in the back-end, and clear all local auth storage
     */
    async logout() {
        await this.$auth.request({
            method: 'post',
            data: {
                query: getStringifiedQuery(logoutQuery)
            }
        }, this.options.endpoint, true);

        return this.$auth.reset();
    }
}
