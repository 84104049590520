// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/backgrounds/auth-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout.layout--auth{background:#000285 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;display:flex;justify-content:center;min-height:100vh}@media screen and (min-width:577px){.layout.layout--auth{align-items:center}}.layout__auth-container{align-items:center;display:inline-flex}@media screen and (max-width:576px){.layout__auth-container{display:block;margin:10rem 0;max-width:var(--grid-maxWidth-page);max-width:calc(var(--grid-maxWidth-page) + var(--grid-side-gap-sm)*2);padding-left:var(--grid-side-gap-sm);padding-right:var(--grid-side-gap-sm);width:100%}}@media screen and (max-width:576px) and screen and (min-width:577px){.layout__auth-container{max-width:calc(var(--grid-maxWidth-page) + var(--grid-side-gap-md)*2);padding-left:var(--grid-side-gap-md);padding-right:var(--grid-side-gap-md)}}@media screen and (max-width:576px) and screen and (min-width:1261px){.layout__auth-container{max-width:calc(var(--grid-maxWidth-page) + var(--grid-side-gap-lg)*2);padding-left:var(--grid-side-gap-lg);padding-right:var(--grid-side-gap-lg)}}@media screen and (min-width:577px){.layout__auth-container{margin:6.5rem 0;width:25rem}}.auth-enter-active,.auth-leave-active{transition:opacity .25s ease-out 0ms}.auth-enter,.auth-leave-to{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
