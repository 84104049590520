<template>
    <page-template>
        <template v-slot:default>
            <asc-page-title>
                <slot name="title">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="title" />
                </slot>
            </asc-page-title>

            <slot name="default">
                <p>{{ message }}</p>
            </slot>
        </template>

        <template v-slot:bottom>
            <asc-section data-width="content-sm">
                <template v-slot:title>
                    <h2>Other options</h2>
                </template>

                <template v-slot:default>
                    <asc-page-nav
                        :routes="[
                            {
                                to: '/updates',
                                label: 'Latest updates'
                            },
                            {
                                to: '/',
                                label: 'Home'
                            },
                            {
                                to: '/members',
                                label: 'Members'
                            },
                            {
                                to: '/about',
                                label: 'About'
                            }
                        ]"
                    />
                    <br><br>
                    <p class="article">If you have any questions, don’t hesitate to contact us via the chat button in the right corner.</p>
                </template>
            </asc-section>
        </template>
    </page-template>
</template>

<script>
import PageTemplate from '~/patterns/templates/page.vue';
import AscPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import AscPageNav from '~/patterns/molecules/page-nav/page-nav.vue';
import AscSection from '~/patterns/organisms/section/section.vue';

export default {
    components: {
        PageTemplate,
        AscPageTitle,
        AscPageNav,
        AscSection
    },

    props: {
        notFound: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    computed: {
        title() {
            if (this.notFound) {
                return 'Page not found';
            } else {
                return 'Something<br />went wrong';
            }
        },

        message() {
            if (this.notFound) {
                return 'The URL of the page you were looking for could have been changed or the page was deleted. Try to use the search bar by clicking on the looking glass in the upper right corner. Chances are you will find the page in no time.';
            } else {
                return 'Something went wrong while retrieving the page you tried to visit. Please try again later.';
            }
        }
    }
};
</script>
