import { isEqual } from 'lodash';

/**
 * Check if there are any differences between the form's current
 * data and its initial data.
 *
 * Empty strings will be converted to be undefined instead.
 *
 * @param   {Object}  formModel    Form's current data
 * @param   {Object}  initialData  Form's initial data
 *
 * @return  {Boolean} Whether data has changed or not.
 */
export function hasChanged(formModel, initialData) {
    const cleanedUpModel = cleanUpEmptyValues(formModel);

    return !isEqual(cleanedUpModel, initialData);
}

/**
 * Cleans up any empty strings within the object's values
 * and sets the to undefined instead.
 *
 * This is required because emptying a form field will return ''
 * instead of the initial data's undefined value.
 *
 * The alternative would be to set the initial data's values to
 * an empty sting (''), but this conflicts with form validation,
 * and unnecessarily bloats the request later on.
 *
 * @param   {Object}  object  Any object containing properties
 *
 * @return  {Object}  object  Cleaned up object
 */
export function cleanUpEmptyValues(object) {
    Object.keys(object).forEach((key) => {
        if (object[key] === '') {
            object[key] = undefined;
        }
    });

    return object;
}
