<template>
    <validation-observer
        ref="validationObserver"
        slim
    >
        <form
            autocomplete="off"
            method="post"
            class="create-update-form"
            @submit.prevent="onSubmit"
        >
            <a id="formTop" ref="formTop" />
            <fieldset>
                <!-- Event type -->
                <validation-provider
                    v-if="type === 'event'"
                    v-slot="validator"
                    rules="required"
                    slim
                >
                    <dn-form-field
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <asc-select
                            v-model="formData.event_type"
                            :options="eventTypes"
                            class="select--lightest"
                            placeholder="Select a type of event"
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <!-- Title -->
                <validation-provider v-slot="validator" rules="required" slim>
                    <dn-form-field
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <dn-textarea
                            v-model="formData.title"
                            label="Title of your update"
                            rows="2"
                            required
                        />

                        <template v-slot:info>
                            <strong>Tip:</strong> Updates with a clear title tend to do better 🙂
                        </template>
                    </dn-form-field>
                </validation-provider>

                <!-- Experience type -->
                <validation-provider
                    v-if="type === 'experience'"
                    v-slot="validator"
                    rules="required"
                    slim
                >
                    <dn-form-field
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <asc-select
                            v-model="formData.experience_type"
                            :options="experienceTypes"
                            class="select--lightest"
                            placeholder="Select a type of experience"
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <!-- Costs -->
                <validation-provider
                    v-if="type === 'experience'"
                    v-slot="validator"
                    rules="required"
                    slim
                >
                    <dn-form-field
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <dn-input
                            id="costs"
                            v-model="formData.costs"
                            type="text"
                            label="Costs"
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <!-- Start date -->
                <validation-provider
                    v-if="type === 'event'"
                    v-slot="validator"
                    rules="required|date|inFuture"
                    slim
                >
                    <dn-form-field
                        :errors="validator.errors"
                        :required="true"
                        class="dn-form-field--compact"
                    >
                        <template v-slot:label>
                            <label for="start_date">Start date</label>
                        </template>

                        <dn-input
                            id="start_date"
                            v-model="formData.event_startdate"
                            type="date"
                            :min="new Date('Y-m-d')"
                            required
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <!-- End date -->
                <validation-provider
                    v-if="type === 'event'"
                    v-slot="validator"
                    rules="date|inFuture"
                    slim
                >
                    <dn-form-field
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <template v-slot:label>
                            <label for="end_date">End date</label>
                        </template>

                        <dn-input
                            id="end_date"
                            v-model="formData.event_enddate"
                            type="date"
                            :min="new Date('Y-m-d')"
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <!-- Message -->
                <validation-provider v-slot="validator" rules="required" slim>
                    <dn-form-field
                        :errors="validator.errors"
                        :required="true"
                        class="dn-form-field--compact"
                    >
                        <template v-slot:label>
                            <label for="message">Message</label>
                        </template>

                        <asc-markdown-editor
                            id="message"
                            v-model="formData.message"
                            required
                        />

                        <template v-slot:info>
                            Share your update in the textbox above.
                        </template>
                    </dn-form-field>
                </validation-provider>

                <!-- Location -->
                <validation-provider
                    v-if="(type === 'event' && formData.event_type !== 'Online event') || type ==='project'"
                    v-slot="validator"
                    :rules="type === 'event' ? 'required': null"
                    slim
                >
                    <dn-form-field
                        :errors="validator.errors"
                        :required="type === 'event'"
                        class="dn-form-field--compact"
                    >
                        <dn-input
                            id="location_name"
                            v-model="formData.location_name"
                            type="text"
                            label="Location"
                            :data-required="type === 'event'"
                            novalidate
                        />

                        <template v-slot:info>
                            <template v-if="type === 'event'">
                                Enter the location of your event. You're free to enter it however you like. Is your event online? Add the link to the event below!
                            </template>

                            <template v-if="type === 'project'">
                                Enter the location of your project. You're free to enter it however you like.
                            </template>
                        </template>
                    </dn-form-field>
                </validation-provider>

                <!-- External URL -->
                <validation-provider v-slot="validator" rules="url" slim>
                    <dn-form-field
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <dn-input
                            id="link_url"
                            v-model="formData.link_url"
                            type="url"
                            label="Website link"
                            :validator="validator"
                            novalidate
                        />

                        <template v-slot:info>
                            Optionally, add a link to a page for more in-depth information. A correctly formatted URL looks something like this: <a href="https://www.amsterdamsmartcity.com">https://www.amsterdamsmartcity.com</a>, including the https:// part.
                        </template>
                    </dn-form-field>
                </validation-provider>

                <!-- Featured image -->
                <dn-form-field class="dn-form-field--compact">
                    <template v-slot:label>
                        <label for="featured_image">Image</label>
                    </template>

                    <asc-image-upload
                        id="featured_image"
                        v-model="formData.featured_image"
                    />

                    <template v-slot:info>
                        Please upload a jpeg or png image smaller than 2MB.
                    </template>
                </dn-form-field>
            </fieldset>

            <!--
             Project fields
             -->
            <fieldset v-if="type === 'project'">
                <legend>Questions</legend>

                <!-- Phase -->
                <dn-form-field
                    v-if="type === 'event'|| type ==='project'"
                    class="dn-form-field--compact"
                >
                    <dn-input
                        v-model="formData.project_phase"
                        type="text"
                        label="Enter the project's current phase"
                        novalidate
                    />

                    <template v-slot:info>
                        What phase is the project currently in? Think about terms like <em>idea</em>, <em>concept</em>, <em>implementation</em> or <em>evaluation</em>.
                    </template>
                </dn-form-field>

                <!-- Project goal -->
                <dn-form-field
                    class="dn-form-field--compact"
                >
                    <dn-textarea
                        v-model="formData.project_goal"
                        label="What is the goal of the project?"
                        rows="4"
                    />

                    <template v-slot:info>
                        Please write something about what urban issue is being solved and what the (aimed) result is.
                    </template>
                </dn-form-field>

                <!-- Project results -->
                <dn-form-field
                    class="dn-form-field--compact"
                >
                    <dn-textarea
                        v-model="formData.project_result"
                        label="What is public value of the project?"
                        rows="4"
                    />

                    <template v-slot:info>
                        How does the project put people in the centre and what is the public value of the project? Please write about the role of residents or users in your project.
                    </template>
                </dn-form-field>

                <!-- Project involved -->
                <dn-form-field
                    class="dn-form-field--compact"
                >
                    <dn-textarea
                        v-model="formData.project_involved"
                        label="Who are involved in the project?"
                        rows="4"
                    />

                    <template v-slot:info>
                        Please write something about the organisations or people that are involved in the project.
                    </template>
                </dn-form-field>

                <!-- Project next step -->
                <dn-form-field
                    class="dn-form-field--compact"
                >
                    <dn-textarea
                        v-model="formData.project_next_step"
                        label="What is the next step?"
                        rows="4"
                    />

                    <template v-slot:info>
                        Please describe what the next step is in achieving the project's goal.
                    </template>
                </dn-form-field>

                <!-- Project learnings -->
                <dn-form-field
                    class="dn-form-field--compact"
                >
                    <dn-textarea
                        v-model="formData.project_learnings"
                        label="What can other cities learn from your project?"
                        rows="4"
                    />

                    <template v-slot:info>
                        What were/are key success factors and what could potentially be replicated to other cities?
                    </template>
                </dn-form-field>
            </fieldset>

            <fieldset>
                <asc-button
                    type="submit"
                    class="dn-button--primary"
                    :disabled="!hasChanges"
                    :data-loading="isLoading"
                >
                    <template v-slot:default><slot name="submit-label">Save</slot></template>
                    <template v-slot:after><asc-icon icon="arrow-right" /></template>
                </asc-button>
            </fieldset>
        </form>
    </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { parseISO, format } from 'date-fns';

import { EVENT_TYPE_MEETUP } from '~/constants/event-types';
import { hasChanged } from '~/helpers/form';

import DnFormField from '~/patterns/molecules/form-field/form-field.vue';
import DnTextarea from '~/patterns/atoms/textarea/textarea.vue';
import DnInput from '~/patterns/atoms/input/input.vue';
import AscImageUpload from '~/patterns/atoms/file-upload/presets/image-upload.vue';
import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import AscSelect from '~/patterns/atoms/select/select.vue';
import AscMarkdownEditor from '~/patterns/atoms/markdown-editor/markdown-editor.vue';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        DnFormField,
        DnInput,
        DnTextarea,
        AscImageUpload,
        AscSelect,
        AscIcon,
        AscButton,
        AscMarkdownEditor
    },

    props: {
        type: {
            type: String,
            default: ''
        },

        value: {
            type: Object,
            default() {
                return {};
            }
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const initialUpdateData = this.value ? { ...this.value } : {};

        return {
            initialUpdateData,
            formData: this.getInitialData(initialUpdateData),
            hasChanges: false
        };
    },

    computed: {
        ...mapGetters({
            experienceTypes: 'update/experienceTypes',
            eventTypes: 'update/eventTypes'
        })
    },

    watch: {
        formData: {
            deep: true,
            handler() {
                this.$emit('input', this.formData);

                this.emitChangedState();
            }
        }
    },

    methods: {
        /**
         * Return a data object containing all properties (for data binding),
         * merged with any data present initially.
         */
        getInitialData(initialUpdate) {
            const initialUpdateData = initialUpdate || this.initialUpdateData,
                initialData = {
                    title: initialUpdateData.title || undefined,
                    message: initialUpdateData.message || undefined,
                    location_name: initialUpdateData?.location?.name || undefined,
                    link_url: initialUpdateData.link_url || undefined,
                    featured_image: initialUpdateData.featured_image || undefined
                };

            if (this.type === 'event') {
                initialData.event_type = initialUpdateData.event_type || EVENT_TYPE_MEETUP;
                initialData.event_startdate = initialUpdateData.event_startdate ? format(parseISO(initialUpdateData.event_startdate), 'yyyy-MM-dd') : undefined;
                initialData.event_enddate = initialUpdateData.event_enddate ? format(parseISO(initialUpdateData.event_enddate), 'yyyy-MM-dd') : undefined;
            }

            if (this.type === 'project') {
                initialData.project_phase = initialUpdateData.project_phase || undefined;
                initialData.project_goal = initialUpdateData.project_goal || undefined;
                initialData.project_result = initialUpdateData.project_result || undefined;
                initialData.project_involved = initialUpdateData.project_involved || undefined;
                initialData.project_next_step = initialUpdateData.project_next_step || undefined;
                initialData.project_learnings = initialUpdateData.project_learnings || undefined;
            }

            if (this.type === 'experience') {
                initialData.experience_type = initialUpdateData.experience_type || undefined;
                initialData.costs = initialUpdateData.costs || undefined;
            }

            return initialData;
        },

        /**
         * Let parent component know if any data in the form was changed
         * relative to the default data.
         */
        emitChangedState() {
            this.hasChanges = hasChanged(this.formData, this.getInitialData());

            this.$emit('hasChanges', this.hasChanges);
        },

        /**
         * Trigger submit event for any parent components to consume.
         */
        async onSubmit() {
            const valid = await this.$refs.validationObserver.validate();

            if (!valid) {
                this.$refs.formTop.scrollIntoView({ behavior: 'smooth' });
                this.$store.commit('toasts/addMessage', {
                    message: 'One or more fields in your update still have errors. Please check if your input is correct and try again',
                    status: 'error'
                });
                this.$emit('invalidSubmit');
                return { invalid: true };
            }
            this.$emit('submit');
        }
    }
};
</script>
