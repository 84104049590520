<template>
    <asc-modal
        :is-visible="isOpen"
        :data-modal-background="step === 2 ? 'grey' : ''"
        data-modal-name="add-update"
        :data-modal-width="step === 2 ? '' : 'small'"
        class="dn-modal--add-update"
        @close="closeModal"
    >
        <template v-slot:header>
            <h1 v-if="step === 1">Create organisation</h1>
            <h1 v-if="step === 2">Select the topics in which your organization is active</h1>
        </template>

        <template v-slot:default>
            <keep-alive>
                <div v-if="step === 1">
                    <validation-observer
                        ref="validationObserver"
                        slim
                    >
                        <form
                            autocomplete="off"
                            method="post"
                            class="create-update-form"
                            @submit.prevent="nextStep"
                        >
                            <a id="formTop" ref="formTop" />
                            <validation-provider v-slot="validator" rules="required|max:60" slim>
                                <asc-form-field :errors="validator.errors" :required="true">
                                    <asc-input v-model="formData.title" label="Organisation name" />
                                </asc-form-field>
                            </validation-provider>

                            <validation-provider v-slot="validator" rules="required" slim>
                                <asc-form-field :errors="validator.errors" :required="true">
                                    <asc-select v-model="formData.type" placeholder="Type of organisation (required)" :options="organisationTypes" />
                                </asc-form-field>
                            </validation-provider>

                            <validation-provider v-slot="validator" rules="required" slim>
                                <asc-form-field :errors="validator.errors" :required="true">
                                    <asc-textarea v-model="formData.description" label="Short organisation description (max 100 words)" />
                                </asc-form-field>
                            </validation-provider>

                            <validation-provider v-slot="validator" rules="url" slim>
                                <asc-form-field :errors="validator.errors" :required="true">
                                    <asc-input v-model="formData.website_url" label="Website link (optional)" />
                                </asc-form-field>
                            </validation-provider>

                            <asc-form-field class="dn-form-field--compact">
                                <asc-image-upload
                                    id="logo"
                                    v-model="formData.logo"
                                />

                                <template v-slot:info>
                                    Please upload a jpeg or png image smaller than 2MB.
                                </template>
                            </asc-form-field>

                            <asc-form-field>
                                <asc-button
                                    type="submit"
                                    class="dn-button--primary"
                                >
                                    <template v-slot:default>Add topics</template>
                                    <template v-slot:after><asc-icon icon="arrow-right" /></template>
                                </asc-button>
                            </asc-form-field>
                        </form>
                    </validation-observer>
                </div>
            </keep-alive>

            <div v-if="step === 2">
                <asc-topics-select v-model="selectedTopics" />
            </div>

            <div v-if="step === 3">
                <article class="article">
                    <h4>Organisation created successfully</h4>

                    <p>
                        Thanks for listing your organisation.
                        <template v-if="$route.path !== '/profile/details'">Check out your organisation profile and inform colleagues to link their profile.</template>
                        <template v-else>You've been added as the owner of the organisation. You can now continue editing the rest of your profile.</template>
                    </p>

                    <icon-link
                        v-if="$route.path === '/profile/details' || $route.path === '/onboarding/profile'"
                        icon="arrow-right"
                        @click="resetAndSetProfile"
                    >
                        Continue editing profile
                    </icon-link>

                    <p v-else>
                        <asc-button
                            class="dn-button--primary dn-button--inline"
                            @click="resetAndRedirect"
                        >
                            View your organisation! 🏙
                        </asc-button>

                        <asc-button
                            v-if="createdOrganisation"
                            class="dn-button--inline"
                            @click="copyLink(`/organisations/${createdOrganisation.slug}`)"
                        >
                            Copy profile link 🔗
                        </asc-button>
                    </p>
                </article>
            </div>

            <transition name="fade">
                <div
                    v-if="isLoading"
                    class="dn-modal__loader"
                >
                    <asc-loader />
                </div>
            </transition>
        </template>

        <template v-slot:footer>
            <div v-if="step === 2">
                <div class="space-between">
                    <asc-button @click="currentStep = 1">
                        <template v-slot:default><asc-icon icon="arrow-left" /></template>
                    </asc-button>

                    <asc-button
                        class="dn-button--primary"
                        :disabled="isLoading"
                        :data-loading="isLoading"
                        @click="submitData"
                    >
                        <template v-slot:default>Create organisation</template>
                        <template v-slot:after><asc-icon icon="arrow-right" /></template>
                    </asc-button>
                </div>
            </div>
        </template>
    </asc-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import createOrganisationMutation from '~/graphql/mutations/organisation/create.graphql';
import formatGqlError from '~/helpers/formatGqlError.js';

import IconLink from '~/patterns/atoms/icon-link/icon-link.vue';
import AscLoader from '~/patterns/atoms/loader/loader.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import AscButton from '~/patterns/atoms/button/button.vue';
import AscSelect from '~/patterns/atoms/select/select.vue';
import AscInput from '~/patterns/atoms/input/input.vue';
import AscTextarea from '~/patterns/atoms/textarea/textarea.vue';
import AscFormField from '~/patterns/molecules/form-field/form-field.vue';

import AscImageUpload from '~/patterns/atoms/file-upload/presets/image-upload.vue';
import AscTopicsSelect from '~/patterns/molecules/topics-select/topics-select.vue';

import AscModal from '~/patterns/organisms/modal/modal.vue';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        AscLoader,
        AscIcon,
        AscButton,
        AscFormField,
        AscInput,
        AscTextarea,
        AscModal,
        AscSelect,
        AscImageUpload,
        AscTopicsSelect,
        IconLink
    },

    data() {
        return {
            isLoading: false,
            isDirty: false,
            currentStep: 1,
            selectedTopics: [],
            formData: {},
            createdOrganisation: {},
            organisationTypes: [
                { label: 'Government', value: 'GOVERNMENT' },
                { label: 'Knowledge Institution', value: 'KNOWLEDGE_INSTITUTION' },
                { label: 'Start-up', value: 'STARTUP' },
                { label: 'Corporate', value: 'CORPORATE' },
                { label: 'SME', value: 'SME' },
                { label: 'Foundation', value: 'FOUNDATION' },
                { label: 'Citizens Initiative', value: 'CITIZENS_INITIATIVE' }
            ]

        };
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal',
            modalData: 'modal/data'
        }),

        isOpen() {
            return this.activeModal === 'add-organisation';
        },

        step() {
            if (this.currentStep) {
                return this.currentStep;
            }

            if (this.modalData && this.modalData.step) {
                return this.modalData.step;
            }

            return 1;
        },

        sanitizedData() {
            const data = { ...this.formData };

            // Unset featured image property if it's not set.
            if (!data.logo) {
                delete data.logo;
            }

            // Set topics
            data.topics = this.selectedTopics;

            return data;
        }
    },

    watch: {
        formData: {
            deep: true,
            handler() {
                this.isDirty = true;
            }
        }
    },

    methods: {
        /**
         * Next
         */
        async nextStep() {
            const valid = await this.$refs.validationObserver.validate();

            if (!valid) {
                this.isLoading = false;
                this.$refs.formTop.scrollIntoView({ behavior: 'smooth' });
                this.$store.commit('toasts/addMessage', {
                    message: 'One or more fields in your organisation still have errors. Please check if your input is correct and try again',
                    status: 'error'
                });
                return { invalid: true };
            }

            this.currentStep++;
        },

        /**
         * Close and reset the modal.
         * If any changes were made, make the user confirm before closing.
         */
        closeModal() {
            let confirmed = true;

            if (this.isDirty) {
                confirmed = confirm('Are you sure you want to stop creating your organisation? Any input will be lost.');
            }

            if (confirmed) {
                this.$store.dispatch('modal/close');
                this.resetModal();
            }
        },

        /**
         * Redirect to the newly created update, and reset the modal for reuse.
         */
        resetAndRedirect() {
            if (this.createdOrganisation && this.createdOrganisation.slug) {
                this.$router.push(`/organisations/${this.createdOrganisation.slug}`);
            } else {
                this.$router.push('/organisations');
            }

            // Prevent weird content reset right before closing the modal
            setTimeout(() => {
                this.resetModal();
            }, 2000);
        },

        /**
         * Close the modal keeping the user on their profile page, updating the organisation
         */
        resetAndSetProfile() {
            this.$store.dispatch('modal/close', { name: 'add-organisation' });
            this.$router.replace({ query: { ...this.$route.query, organisationId: this.createdOrganisation.id } });

            // Prevent weird content reset right before closing the modal
            setTimeout(() => {
                this.resetModal();
            }, 2000);
        },

        /**
         * Reset the add update modal to its initial state.
         */
        resetModal() {
            this.currentStep = 1;
            this.loading = false;
            this.selectedTopics = [];
            this.formData = {};
            this.isDirty = false;
            this.createdOrganisation = null;
        },

        /**
         * Submit the data gathered in the different steps to the graphQL api.
         */
        async submitData() {
            this.isLoading = true;

            if (!this.selectedTopics.length) {
                this.isLoading = false;
                this.$store.commit('toasts/addMessage', {
                    message: 'Please select one or more topics for your organisation.',
                    status: 'error'
                });
                return;
            }

            try {
                const response = await this.$gql.executeQuery(createOrganisationMutation, this.sanitizedData);

                if (response.data && response.data.createOrganisation) {
                    this.createdOrganisation = response.data.createOrganisation;
                }

                setTimeout(() => {
                    this.currentStep = 3;
                    this.isLoading = false;

                    // Reset isDirty variable, so no prompt is shown in step 3 before closing.
                    this.isDirty = false;
                }, 2000);
            } catch (e) {
                this.isLoading = false;

                const messages = formatGqlError(e);

                messages.forEach((message) => {
                    this.$store.commit('toasts/addMessage', {
                        message: message.text,
                        status: 'error'
                    });
                });
            }
        },

        async copyLink(path) {
            const url = `${window.location.protocol}//${window.location.host}/${path}`;
            try {
                await this.$copyText(url);
                this.$store.commit('toasts/addMessage', {
                    message: 'The link was copied to your clipboard!',
                    status: 'success'
                });
            } catch {
                this.$store.commit('toasts/addMessage', {
                    message: 'We couldn\'t copy the link',
                    status: 'error'
                });
            }
        }
    }
};
</script>

<style src="./add-organisation.less" lang="less"></style>
