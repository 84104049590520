import colors from '~/assets/styles/theme/color.js';

/**
 * Return an array of the available tint objects, as defined in the css theme.
 * Tints can be connected to channels in the CMS.
 *
 * @return  {Array}  Array of tints, in a {tintName: '#hexvalue'} format.
 */
export const getTints = function() {
    return colors.tint;
};

/**
 * Return the names of all available tints in a flattened array.
 *
 * @return  {Array}  Array of tint key, like: ['red', 'blue', 'gold']
 */
export const getTintKeys = function() {
    return Object.keys(colors.tint);
};

/**
 * Check if a provided tint is valid.
 *
 * @return  {Boolean} Is this a valid tint?
 */
export const isTintValid = function(tintKey) {
    return getTintKeys().includes(tintKey);
};

/**
 * Array of possible background properties corresponding to tints.
 *
 * @return  {Array}  Array of background values, like: ['tint-red', 'tint-blue']
 */
export const getTintBackgrounds = function() {
    return Object.keys(colors.tint).map(name => `tint-${name}`);
};

/**
 * Return an array of the available tint gradient objects, as defined in the css theme.
 * Tints can be connected to channels in the CMS, each of which has a gradient variant.
 *
 * @return  {Array}  Array of tints, in a {tintName: 'gradient()'} format.
 */
export const getGradients = function() {
    return colors.gradient;
};

/**
 * Return the names of all available tints in a flattened array.
 *
 * @return  {Array}  Array of tint key, like: ['red', 'blue', 'gold']
 */
export const getGradientKeys = function() {
    return Object.keys(colors.gradient);
};

/**
 * Check if a provided gradient tint is valid.
 *
 * @return  {Boolean} Is this a valid gradient tint?
 */
export const isGradientValid = function(tintKey) {
    return getGradientKeys().includes(tintKey);
};

/**
 * Array of possible background properties corresponding to gradient tints.
 *
 * @return  {Array}  Array of background values, like: ['gradient-red', 'gradient-blue']
 */
export const getGradientBackgrounds = function() {
    return Object.keys(colors.gradient).map(name => `gradient-${name}`);
};

/**
 * Return the corresponding background based on the provided tint's name.
 *
 * @return  {String}  Background value string
 */
export const getGradientBackgroundByTint = function(tintKey) {
    if (!tintKey || getGradientKeys().includes(tintKey)) {
        return false;
    }

    return `gradient-${tintKey}`;
};
