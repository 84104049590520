<template>
    <dn-modal
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div v-if="!!$slots.header" class="dn-modal__header"><slot name="header" /></div>
        <div v-if="!!$slots.default" class="dn-modal__body"><slot name="default" /></div>
        <div v-if="!!$slots.footer" class="dn-modal__footer"><slot name="footer" /></div>

        <template v-slot:close-button-label>
            <asc-icon icon="cross" />
        </template>
    </dn-modal>
</template>
<script>
import DnModal from '@digitalnatives/modal';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnModal,
        AscIcon
    }
};
</script>

<style src="./modal.less" lang="less"></style>
