import { render, staticRenderFns } from "./update-type-link.vue?vue&type=template&id=21fb54e4"
import script from "./update-type-link.vue?vue&type=script&lang=js"
export * from "./update-type-link.vue?vue&type=script&lang=js"
import style0 from "./update-type-link.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports