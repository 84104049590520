import Bugsnag from '@bugsnag/js';
import allTopicsAndChannelsQuery from '~/graphql/queries/topic/allTopicsAndChannels.graphql';

export default {
    async fetchChannels({ commit }) {
        let channels = [];

        try {
            const results = await this.$gql.executeQuery(allTopicsAndChannelsQuery);
            channels = results.data.topics ? results.data.topics.data : [];
        } catch (e) {
            Bugsnag.notify(e);
        }

        commit('setChannels', channels);
    }
};
