import Vue from 'vue';
import VueCookieConsent from '@digitalnatives/cookie-consent';

export default (context, inject) => {
    Vue.use(VueCookieConsent, {
        categories: [
            { id: 'analytical' },
            { id: 'livechat' }
        ]
    });

    inject('cookieConsent', Vue.$cookieConsent);
};
