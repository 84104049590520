/**
 * Colors
 * ====================
 * Individual themes may have more or less colours than included here, but these are the base colours
 * which the toolkit may use.
 *
 * It is reccomended to keep these colours and override them with a different colour in your app, even if you don't use
 * the colours within your own theme, to prevent unthemed parts of the toolkit.
 *
 * These colours are all prefixed with `--color`.
 */
export default {
    darkest: 'var(--color-black-100)',
    lightest: '#fff',
    primary: '#0000FF',
    secondary: '#ACDCC6',
    tertiary: '#FF60B7',

    tint: {
        red: '#C23E43',
        blue: '#027994',
        teal: '#167A69',
        gold: '#8D6800',
        purple: '#422276',
        violet: '#AB1267',
        grey: '#444A4D'
    },

    gradient: {
        red: 'linear-gradient(90deg, var(--color-primary) 20.31%, var(--color-tint-red) 100%)',
        blue: 'linear-gradient(90deg, var(--color-primary) 21.35%, var(--color-tint-blue) 100%)',
        teal: 'linear-gradient(90deg, var(--color-primary) 18%, var(--color-tint-teal) 100%)',
        gold: 'linear-gradient(90deg, var(--color-primary) 22.92%, var(--color-tint-gold) 100%)',
        purple: 'linear-gradient(90deg, var(--color-primary) 17.48%, var(--color-tint-purple) 100%)',
        violet: 'linear-gradient(90deg, var(--color-primary) 19.79%, var(--color-tint-violet) 100%)',
        grey: 'linear-gradient(90deg, var(--color-primary) 18%, var(--color-tint-grey) 100%)'
    },

    // Alpha-based grays
    black: {
        10: 'rgba(0, 0, 0, .04)',
        20: 'rgba(0, 0, 0, .1)',
        30: 'rgba(0, 0, 0, .2)',
        40: 'rgba(0, 0, 0, .36)',
        50: 'rgba(0, 0, 0, .54)',
        80: 'rgba(0, 0, 0, .8)',
        100: '#180032'
    },
    white: {
        10: 'rgba(255, 255, 255, .1)',
        20: 'rgba(255, 255, 255, .2)',
        30: 'rgba(255, 255, 255, .3)',
        50: 'rgba(255, 255, 255, .56)',
        80: 'rgba(255, 255, 255, .8)'
    },

    // Shades of gray, from light (100) to heavy (dark) (900)
    // In order to maintain contrast and consistency, it is reccomended to stick to 9 grayscales in your pallette.
    // If a colour in the design doesn't match one of these shades, try and replace it with its nearest neighbour instead of adding new shades.
    gray: {
        100: '#f3f3f3',
        200: 'var(--color-black-20)',
        300: '#cccccc',
        400: 'var(--color-black-30)',
        500: '#6C6C6C',
        600: 'var(--color-black-50)',
        700: 'var(--color-black-50)',
        800: 'var(--color-black-80)',
        900: 'var(--color-black-80)'
    },

    // These are essentially state colours used for notifications and the like.
    highlight: {
        success: '#1EAC70',
        info: 'var(--color-darkest)',
        warning: 'orange',
        error: '#DF3755'
    },

    bodyText: 'var(--color-darkest)',
    bodyBg: 'var(--color-lightest)'
};
