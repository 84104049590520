import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import onError from '~/modules/bugsnag/onError.js';

// Parse lodash template options into usable JS
const options = JSON.parse('{"browserApiKey":"67a46b9b981521d03cecd6dfb8f441a8","serverApiKey":"67a46b9b981521d03cecd6dfb8f441a8","releaseStage":"production","enabledReleaseStages":["staging","production"]}');

Bugsnag.start({
    apiKey: options.browserApiKey,
    plugins: [new BugsnagPluginVue(Vue)],
    releaseStage: options.releaseStage,
    enabledReleaseStages: options.enabledReleaseStages,
    onError
});
