<template>
    <nav class="navigation-left" :data-collapsed="isCollapsed">
        <nuxt-link
            to="/"
            class="navigation-left__logo"
        >
            <img src="~assets/img/logos/asc-logo-pink.svg">
        </nuxt-link>

        <transition name="fade">
            <div v-if="showBackButton" class="navigation-left__back">
                <asc-button
                    class="dn-button--link dn-button--move-icon"
                    @click="navigateBack"
                >
                    <template v-slot:before>
                        <asc-icon icon="chevron-left" />
                    </template>

                    <template v-slot:default>
                        Back
                    </template>
                </asc-button>
            </div>
        </transition>
    </nav>
</template>

<script>
import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscIcon,
        AscButton
    },

    props: {
        canCollapse: {
            type: Boolean,
            default: true
        },

        allowBackButton: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        showBackButton() {
            if (!this.allowBackButton || this.$route.name === 'index') {
                return false;
            }

            return true;
        },

        isCollapsed() {
            if (!this.canCollapse) {
                return false;
            }

            // @todo make this fancier (08-2020)
            // if (this.$store.getters['ui/heroInView']) {
            //     return false;
            // }

            return this.$store.getters['ui/isNavigationCollapsed'];
        }
    },

    methods: {
        navigateBack() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="less" src="./navigation-left.less"></style>
