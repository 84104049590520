
<template>
    <div class="auth-message" :data-type="type">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'info'
        }
    }
};
</script>

<style lang="less">
.auth-message {
    position: relative;
    margin: 0 0 var(--spacing-md);
    padding: 0;

    color: var(--color-highlight-info);
    font-size: var(--font-size-md);
    line-height: var(--lineHeight-lg);
}

.auth-message__icon {
    position: absolute;
    top: .25em;
    right: 0;
}

.auth-message[data-type='error'] {
    color: var(--color-highlight-error);
}

.auth-message[data-type='success'] {
    color: var(--color-highlight-success);
}
</style>
