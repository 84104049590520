import { formatToDateTimeTz } from '~/helpers/date.js';

/**
 * Sanitize the data from the create / edit update form
 *
 * This function makes sure the data is properly formatted
 * for the graphql API to receive. It serves as a bridge
 * between the simplified form input structure and the
 * object properties the endpoint needs to function.
 *
 * @param   {Object}  formData  Data as set by the update form
 *
 * @return  {Object}  object  Sanitized form data, ready to be stored
 */
export function sanitizeUpdateFormData(formData) {
    if (!formData) {
        return formData;
    }

    const data = { ...formData };

    // Unset featured image property if it's not set.
    if (!data.featured_image) {
        delete data.featured_image;
    }

    // Format location object property
    if (formData.location_name) {
        data.location = {
            name: formData.location_name
        };

        delete data.location_name;
    }

    // Format event dates
    if (formData.event_startdate) {
        data.event_startdate = formatToDateTimeTz(formData.event_startdate);
    }

    if (formData.event_enddate) {
        data.event_enddate = formatToDateTimeTz(formData.event_enddate);
    }

    return data;
}
