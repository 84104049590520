
<template>
    <ul class="auth-links">
        <li v-if="$slots.primary"><slot name="primary" /></li>
        <li v-if="$slots.secondary"><slot name="secondary" /></li>
    </ul>
</template>

<script>
export default {

};
</script>

<style lang="less">
.auth-links {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 1.25rem 0;
    padding: 0;
    list-style: none;

    li {
        padding: 0;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.auth-links a {
    .typography-small();
    color: var(--color-black-50);
    font-size: .875rem;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
    transition: var(--transition-link);

    &:hover {
        color: var(--color-darkest);
    }
}
</style>
