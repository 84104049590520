<script>
import { Layout } from '@digitalnatives/styleguide';
import StyleguideRoutes from '~/styleguide/config/routes.js';

export default {
    extends: Layout,

    data() {
        return {
            routes: StyleguideRoutes
        };
    }
};
</script>

<style lang="less">
body .styleguide {
    overflow: visible;
}

.styleguide-section__header__body h1 {
    font-weight: 300;
}

.styleguide-section[data-width='bleed'] {
    .styleguide-section__body {
        padding-left: 0;
        padding-right: 0;

        .styleguide-row__title {
            position: static;
            display: block;
            width: 100%;
            max-width: 40rem;
            margin: 0 auto 5rem auto;
            padding: 0 2.5rem;
            transform: none;
            text-align: center;
        }
    }
}
</style>
