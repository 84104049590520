<template>
    <asc-error :not-found="error && error.statusCode === 404" />
</template>

<script>
import AscError from '~/patterns/organisms/error/error.vue';

export default {
    components: {
        AscError
    },

    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>
