<template>
    <div class="template template--page">
        <main class="template__body">
            <content-layout>
                <article class="article">
                    <slot />
                </article>
            </content-layout>
        </main>

        <div class="template__bottom" data-background="grey">
            <slot name="bottom" />
        </div>
    </div>
</template>

<script>
import ContentLayout from '~/patterns/organisms/content-layout/content-layout.vue';

export default {
    components: {
        ContentLayout
    }
};
</script>

<style lang="less">
.template--page {
    display: flex;
    flex-direction: column;

    .template__body {
        // Make sure the template_bottom is always sticking to the footer.
        flex: 1 0 auto;
    }

    .template__bottom {
        margin-top: 2.5rem;
    }

    .content-layout {
        .content-sm-container();

        .content-layout__body {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @media @q-md-min {
        .template__bottom {
            margin-top: 4.5rem;
        }
    }
}
</style>
