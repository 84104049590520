<template>
    <transition name="fade">
        <div v-if="isVisible" class="page-loader">
            <asc-loader />
        </div>
    </transition>
</template>

<script>
import AscLoader from '~/patterns/atoms/loader/loader.vue';

export default {
    components: {
        AscLoader
    },

    computed: {
        isVisible() {
            return this.$store.getters['ui/isPageLoaderVisible'];
        }
    }
};
</script>

<style lang="less" src="./page-loader.less" scoped>
