export default {
    setChannels(state, payload) {
        state.channels = payload;
    },

    setTopics(state, payload) {
        state.topics = payload;
    },

    updateTopicById(state, payload) {
        state.channels.forEach((channel) => {
            if (channel.children) {
                const index = channel.children.findIndex(topic => topic.id === payload.id);
                if (index !== -1) {
                    channel.children.splice(index, 1, payload);
                }
            }
        });
    }
};
