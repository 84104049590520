<template>
    <asc-icon :svg-attrs="{ width: 24, height: 24 }" viewbox="0 0 24 24">
        <template v-slot:title>Project</template>
        <template v-slot:default>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.85 14.2H21.59L16.64 7.6L21.59 1H4.925H3V2.925V14.2V21.075C3 22.142 3.858 23 4.925 23C5.992 23 6.85 22.142 6.85 21.075V14.2Z" fill="#180032" />
        </template>
    </asc-icon>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon }
};
</script>
