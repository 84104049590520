import {
    TYPE_NEWS,
    TYPE_EVENT,
    TYPE_REQUEST,
    TYPE_OPPORTUNITY,
    TYPE_EXPERIENCE,
    TYPE_PROJECT
} from '~/constants/update-types';

import {
    EVENT_TYPE_MEETUP,
    EVENT_TYPE_CONFERENCE,
    EVENT_TYPE_WORKSHOP,
    EVENT_TYPE_LECTURE,
    EVENT_TYPE_ONLINE
} from '~/constants/event-types';

export default () => ({
    types: [
        {
            value: TYPE_NEWS,
            label: 'News',
            labelSingular: 'News'
        },
        {
            value: TYPE_EVENT,
            label: 'Events',
            labelSingular: 'Event'
        },
        {
            value: TYPE_REQUEST,
            label: 'Requests',
            labelSingular: 'Request'
        },
        {
            value: TYPE_OPPORTUNITY,
            label: 'Opportunities',
            labelSingular: 'Opportunity'
        },
        {
            value: TYPE_EXPERIENCE,
            label: 'Experiences',
            labelSingular: 'Experience'
        },
        {
            value: TYPE_PROJECT,
            label: 'Projects',
            labelSingular: 'Project'
        }
    ],

    eventTypes: [
        {
            value: EVENT_TYPE_MEETUP,
            label: EVENT_TYPE_MEETUP
        },
        {
            value: EVENT_TYPE_CONFERENCE,
            label: EVENT_TYPE_CONFERENCE
        },
        {
            value: EVENT_TYPE_WORKSHOP,
            label: EVENT_TYPE_WORKSHOP
        },
        {
            value: EVENT_TYPE_LECTURE,
            label: EVENT_TYPE_LECTURE
        },
        {
            value: EVENT_TYPE_ONLINE,
            label: EVENT_TYPE_ONLINE
        }
    ],

    experienceTypes: [
        {
            value: 'tour',
            label: 'Tour'
        },
        {
            value: 'visit',
            label: 'Visit'
        },
        {
            value: 'exhibition',
            label: 'Exhibition'
        },
        {
            value: 'workshop',
            label: 'Workshop'
        },
        {
            value: 'online-meeting',
            label: 'Online meeting'
        },
        {
            value: 'in-person',
            label: 'In-person meeting'
        },
        {
            value: 'other',
            label: 'Other'
        }
    ]
});
