<template>
    <form
        class="searchbar"
        :data-visible="isSearchOpen"
        :style="{
            'margin-left': leftOffset,
            'margin-right': rightOffset
        }"
        @submit.prevent="doSearch"
    >
        <input ref="input" v-model="searchQuery" type="text" class="searchbar__input" placeholder="Search">

        <asc-button type="button" class="dn-button--clean dn-button--inline searchbar__submit" @click.prevent="clearAndClose">
            <span class="hidden-xxs hidden-xs hidden-sm">Clear</span>
            <asc-icon icon="cross" class="hidden-md hidden-lg hidden-xl hidden-xxl" title="Clear" />
        </asc-button>
    </form>
</template>

<script>
import Vue from 'vue';

import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscButton,
        AscIcon
    },

    data() {
        return {
            leftOffset: '3rem',
            rightOffset: '3rem',
            searchQuery: ''
        };
    },

    computed: {
        isSearchOpen() {
            return this.$store.getters['ui/isSearchOpen'](this.$route);
        }
    },

    watch: {
        isSearchOpen(value) {
            Vue.nextTick(() => {
                this.calculateOffsets();
                if (value === true) {
                    this.$refs.input.focus();
                }
            });
        },

        '$route'(to, from) {
            if (to.path !== '/search') {
                this.$store.dispatch('ui/hideSearch');
            } else {
                if (this.$route.query.q) {
                    this.searchQuery = this.$route.query.q;
                }
                Vue.nextTick(() => {
                    this.calculateOffsets();
                });
            }
        }
    },

    created() {
        if (this.$route.query.q) {
            this.searchQuery = this.$route.query.q;
        }
    },

    beforeMount() {
        this.calculateOffsets();
        window.addEventListener('resize', this.calculateOffsets);
    },

    methods: {
        clearAndClose() {
            this.$store.dispatch('ui/hideSearch');
            this.searchQuery = '';
            this.$router.replace({ query: null });
        },

        doSearch() {
            this.$router.push({ path: '/search', query: { ...this.$route.query, q: this.searchQuery } });
        },

        calculateOffsets() {
            if (typeof document !== 'undefined') {
                this.leftOffset = document.querySelector('.navigation-left')?.clientWidth + 'px';
                this.rightOffset = document.querySelector('.navigation-right')?.clientWidth + 'px';
            }
        }
    }
};
</script>

<style lang="less" src="./searchbar.less" />
