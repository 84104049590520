<template>
    <section class="accordion">
        <article
            v-for="(item, index) in items"
            :key="index"
            class="accordion__row"
            :data-collapsed="visibleRowIndexes.indexOf(index) === -1"
        >
            <header class="accordion__header" tabindex="0" aria-label="Expand" @click="toggleRow(index)" @keydown.enter="toggleRow(index)">
                <slot name="title" :item="item">
                    <h4>{{ item.title }}</h4>
                </slot>

                <small class="accordion__title-suffix">
                    <slot name="titleSuffix" :item="item" />
                </small>

                <asc-icon class="accordion__icon" icon="chevron-down" />
            </header>

            <div class="accordion__content">
                <slot :item="item">
                    {{ item.content }}
                </slot>
            </div>
        </article>
    </section>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscIcon
    },

    props: {
        openIndexes: {
            type: Array,
            required: false,
            default: () => []
        },

        items: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    data() {
        return {
            visibleRowIndexes: []
        };
    },

    created() {
        this.visibleRowIndexes = [...this.openIndexes];
    },

    methods: {
        toggleRow(index) {
            const arrayIndex = this.visibleRowIndexes.indexOf(index);

            if (arrayIndex === -1) {
                this.visibleRowIndexes.push(index);
            } else {
                this.visibleRowIndexes.splice(arrayIndex, 1);
            }
        }
    }
};
</script>

<style lang="less" src="./accordion.less"></style>
