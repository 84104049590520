<template>
    <asc-modal
        :is-visible="isOpen"
        data-modal-name="cookie-modal"
        data-modal-width="small"
        @close="closeModal"
    >
        <template v-slot:header>
            <h1>Privacy settings</h1>
        </template>

        <template v-slot:default>
            <div class="article">
                <p class="lead">
                    We ask for cookies to give you the best community experience. Check our <nuxt-link to="/privacy">privacy policy</nuxt-link> for more info.
                </p>
            </div>

            <client-only>
                <asc-cookie-preferences :preferences.sync="preferences" />
            </client-only>
        </template>

        <template v-slot:footer>
            <client-only>
                <asc-button
                    class="dn-button--primary"
                    :data-loading="isSaving"
                    @click="save"
                >
                    <span v-if="isSaving">Saving…</span>
                    <span v-else>Accept</span>
                </asc-button>
            </client-only>
        </template>
    </asc-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import AscCookiePreferences from './cookie-preferences.vue';
import AscModal from '~/patterns/organisms/modal/modal.vue';
import AscButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        AscModal,
        AscCookiePreferences,
        AscButton
    },

    data() {
        return {
            preferences: {
                //
            }
        };
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal'
        }),

        isOpen() {
            return this.activeModal === 'cookie-modal';
        },

        isSaving() {
            return this.$cookieConsent && this.$cookieConsent.isSaving();
        },

        hasChanged() {
            return this.$cookieConsent && this.$cookieConsent.hasChanged();
        }
    },

    beforeMount() {
        // Create a clone preferences object where all categories are marked as checked without affecting the actual given consent.
        if (!this.$cookieConsent.isCookiebarDismissed()) {
            this.preferences = this.$cookieConsent.getCategories().map(({ id }) => id);
        } else {
            this.preferences = [...this.$cookieConsent.cookieVm.checkedCategories];
        }
    },

    methods: {
        save() {
            // Put our local preferences clone back into cookies VM
            this.$cookieConsent.cookieVm.checkedCategories = [...this.preferences];
            this.$cookieConsent.save();
        },

        closeModal() {
            const confirmed = confirm('By closing this window you\'re also accepting the current privacy settings. Click "OK" to accept, or cancel to make changes.');

            if (confirmed) {
                this.save();
            }
        }
    },
};
</script>

<style src="./cookie-modal.less" lang="less"></style>
