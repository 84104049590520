export default [
    {
        path: '/styleguide',
        label: 'Foundation',
        component: 'styleguide/pages/foundation/index.vue'
    },
    {
        path: '/styleguide/elements',
        label: 'Elements',
        component: 'styleguide/pages/elements/index.vue'
    },
    {
        path: '/styleguide/components',
        label: 'Components',
        component: 'styleguide/pages/components/index.vue'
    },
    {
        path: '/styleguide/layout',
        label: 'Layout',
        component: 'styleguide/pages/layout/index.vue'
    }
];
