<template>
    <asc-icon :svg-attrs="{ width: 24, height: 24 }" viewbox="0 0 24 24">
        <template v-slot:title>Event</template>
        <template v-slot:default>
            <path d="M18.5 5.5V4.25C18.5 3.01 17.49 2 16.25 2C15.01 2 14 3.01 14 4.25V5.5H10V4.25C10 3.01 8.99 2 7.75 2C6.51 2 5.5 3.01 5.5 4.25V5.5H2V22H8.5H15.5H22V5.5H18.5ZM18 18H6V10H18V18Z" fill="#180032" />
        </template>
    </asc-icon>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: { AscIcon }
};
</script>
