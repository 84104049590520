export const EVENT_TYPE_MEETUP = 'Meet-up';
export const EVENT_TYPE_CONFERENCE = 'Conference';
export const EVENT_TYPE_WORKSHOP = 'Masterclass / workshop';
export const EVENT_TYPE_LECTURE = 'Lecture / presentation';
export const EVENT_TYPE_ONLINE = 'Online event';

export default [
    EVENT_TYPE_MEETUP,
    EVENT_TYPE_CONFERENCE,
    EVENT_TYPE_WORKSHOP,
    EVENT_TYPE_LECTURE,
    EVENT_TYPE_ONLINE
];
