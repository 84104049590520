import { extend, setInteractionMode } from 'vee-validate';
// eslint-disable-next-line camelcase
import { required, email, regex, alpha_dash, max } from 'vee-validate/dist/rules.umd.js';
import { parseDate } from '~/helpers/date';

// Set interaction mode
setInteractionMode('eager');

extend('regex', {
    ...regex,
    message: 'The field value is not correct'
});

extend('url', (value) => {
    if (/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i.test(value)) {
        return true;
    }

    return 'That doesn\'t seem to be a valid URL.';
});

extend('email', {
    ...email,
    message: 'Please enter your e-mail address'
});

extend('required', {
    ...required,
    message: 'This field is required'
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

extend('alpha_dash', {
    // eslint-disable-next-line camelcase
    ...alpha_dash,
    message: 'Only letters, -, and _ characters are allowed.'
});

extend('date', (date) => {
    if (parseDate(date, 'yyyy-MM-dd')) {
        return true;
    }

    return 'Please enter a valid date, following the yyyy-mm-dd format.';
});

extend('inFuture', (date) => {
    if (parseDate(date, 'yyyy-MM-dd') >= new Date().setDate(new Date().getDate() - 1)) {
        return true;
    }

    return 'Please enter a date that\'s yet to come.';
});

extend('max', {
    ...max,
    message: 'There are too many characters in this field, please keep it short'
});
