<template>
    <component
        :is="element"
        v-bind="$attrs"
        class="icon-link"
        :class="{ 'icon-link--reverse': reverse }"
        v-on="$listeners"
    >
        <asc-icon v-if="reverse" :icon="iconName" class="icon-link__icon" />

        <slot />

        <asc-icon v-if="!reverse" :icon="iconName" class="icon-link__icon" />
    </component>
</template>

<script>
import AscIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        AscIcon
    },

    props: {
        iconName: {
            type: String,
            default: 'arrow-right',
            required: false
        },
        element: {
            type: String,
            default: 'a',
            required: false
        },
        reverse: {
            type: Boolean,
            default: false,
            required: false
        }
    }
};
</script>

<style lang="less">
.icon-link {
    cursor: pointer;
    font-size: var(--font-size-md);
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover, &:focus {
        .icon-link__icon {
            transform: translateX(3px);
        }
    }
}

.dn-icon.icon-link__icon {
    position: relative;
    top: 1px;
    margin-left: .5em;

    font-size: .7778em;

    transition: transform var(--transition-fast);
}

.icon-link--label {
    .typography-label();
}

.icon-link--large {
    .typography-text();

    .dn-icon.icon-link__icon {
        font-size: 1em;
        top: 4px;
        margin-left: .25em;
    }
}

.icon-link--reverse {
    &:hover, &:focus {
        .icon-link__icon {
            transform: translateX(-3px);
        }
    }

    .dn-icon.icon-link__icon {
        margin-left: 0;
        margin-right: .5em;
    }
}

.icon-link--no-animate {
    &:hover, &:focus {
        color: var(--color-darkest);

        .icon-link__icon {
            transform: translateX(0);
        }
    }

    &.icon-link--reverse {
        &:hover, &:focus {
            .icon-link__icon {
                transform: translateX(0);
            }
        }
    }
}
</style>
