// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/placeholders/image.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dn-file-upload.dn-file-upload--image .dn-file-upload__preview{border-radius:var(--borderRadius-md);margin-right:1.25rem;overflow:hidden}.dn-file-upload.dn-file-upload--image .dn-file-upload__preview img{display:block;height:auto;width:8rem}.dn-file-upload.dn-file-upload--image .dn-file-upload__placeholder{background:var(--color-black-20) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;background-size:cover;height:8rem;position:relative;width:8rem}@media screen and (max-width:576px){.dn-file-upload.dn-file-upload--image{display:block}.dn-file-upload.dn-file-upload--image .dn-file-upload__preview{display:block;margin-bottom:var(--spacing-base)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
