<template>
    <dn-file-upload
        v-model="imageFile"
        :uploading.sync="imageIsUploading"
        :force-show-remove-button="!!value"
        :input-attributes="{
            accept: 'image/png, image/jpeg'
        }"
        class="dn-file-upload--image"
        @startUpload="handleImageUpload"
        @error="handleImageError"
    >
        <template v-slot:preview>
            <transition name="fade" mode="out-in">
                <img
                    v-if="imageUrl && !imageIsUploading"
                    key="image"
                    :src="imageUrl"
                >

                <div
                    v-else
                    key="placeholder"
                    class="dn-file-upload__placeholder"
                />
            </transition>
        </template>

        <template v-slot:uploadLabel>
            Upload an image
        </template>

        <template v-slot:clearFileLabel>
            Remove this image
        </template>
    </dn-file-upload>
</template>

<script>
import uploadFileMutation from '~/graphql/mutations/file/uploadMutation.graphql';
import formatGqlError from '~/helpers/formatGqlError.js';

import DnFileUpload from '~/patterns/atoms/file-upload/file-upload.vue';

export default {
    components: {
        DnFileUpload
    },

    props: {
        value: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            imageIsUploading: false,
            imageFile: undefined,
            imagePath: undefined,
            imageUrl: this.value
        };
    },

    watch: {
        imageFile(file) {
            // Support clearing the value
            if (this.value && !file) {
                this.imagePath = null;
                this.imageUrl = null;
                this.updateValue(null);
            }
        }
    },

    methods: {
        async handleImageUpload(fileFormData) {
            this.imageIsUploading = true;

            try {
                const response = await this.$gql.executeMultipartQuery(
                    uploadFileMutation,
                    fileFormData,
                    { file: null },
                    // This maps the "file" graphql variable used in the query, to the "file" property in fileFormData
                    { file: ['variables.file'] }
                );
                this.imageIsUploading = false;

                // Set the preview URL and the actual path to finally save when clicking the save button.
                this.imageUrl = response.data.uploadTemporaryFile.url;
                this.imagePath = response.data.uploadTemporaryFile.path;
                this.updateValue(this.imagePath);
            } catch (e) {
                const messages = formatGqlError(e);

                messages.forEach((message) => {
                    this.$store.commit('toasts/addMessage', {
                        message: 'Could not upload your image. Please choose a jpeg or png image smaller than 2MB.',
                        status: 'error'
                    });
                });

                this.imageIsUploading = false;
                this.imageFile = null;
            }
        },

        handleImageError(e) {
            const messages = formatGqlError(e);

            messages.forEach((message) => {
                this.$store.commit('toasts/addMessage', {
                    message: 'Could not upload your image. Please choose a jpeg or png image smaller than 2MB.',
                    status: 'error'
                });
            });

            this.imageIsUploading = false;
        },

        updateValue(value) {
            this.$emit('input', value);
        }
    }
};
</script>

<style src="./image-upload.less" lang="less"></style>
