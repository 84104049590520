<template>
    <component
        :is="$attrs.to ? 'nuxt-link' : 'button'"
        v-if="updateType"
        class="update-type-link"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <component
            :is="`${updateType.value}-icon`"
            class="dn-icon--square"
        />

        <span v-if="!hideLabel" class="update-type-link__label">
            <slot name="default">{{ updateType.label || '' }}</slot>
        </span>
    </component>
</template>

<script>
import EventIcon from '~/patterns/atoms/icon/presets/update-types/event.vue';
import NewsIcon from '~/patterns/atoms/icon/presets/update-types/news.vue';
import OpportunityIcon from '~/patterns/atoms/icon/presets/update-types/opportunity.vue';
import ProjectIcon from '~/patterns/atoms/icon/presets/update-types/project.vue';
import RequestIcon from '~/patterns/atoms/icon/presets/update-types/request.vue';
import ExperienceIcon from '~/patterns/atoms/icon/presets/update-types/experience.vue';

export default {
    components: {
        EventIcon,
        NewsIcon,
        OpportunityIcon,
        ProjectIcon,
        RequestIcon,
        ExperienceIcon
    },

    props: {
        hideLabel: {
            type: Boolean,
            default: false,
            required: false
        },
        updateType: {
            type: Object,
            required: true,
            default: null
        }
    }
};
</script>

<style src="./update-type-link.less" lang="less"></style>
