var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-template',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('asc-page-title',[_vm._t("title",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})]})],2),_vm._v(" "),_vm._t("default",function(){return [_c('p',[_vm._v(_vm._s(_vm.message))])]})]},proxy:true},{key:"bottom",fn:function(){return [_c('asc-section',{attrs:{"data-width":"content-sm"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',[_vm._v("Other options")])]},proxy:true},{key:"default",fn:function(){return [_c('asc-page-nav',{attrs:{"routes":[
                        {
                            to: '/updates',
                            label: 'Latest updates'
                        },
                        {
                            to: '/',
                            label: 'Home'
                        },
                        {
                            to: '/members',
                            label: 'Members'
                        },
                        {
                            to: '/about',
                            label: 'About'
                        }
                    ]}}),_vm._v(" "),_c('br'),_c('br'),_vm._v(" "),_c('p',{staticClass:"article"},[_vm._v("If you have any questions, don’t hesitate to contact us via the chat button in the right corner.")])]},proxy:true}])})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }