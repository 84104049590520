<script>
import formatGqlError from '~/helpers/formatGqlError';

export default {
    data() {
        return {
            isLoading: false,
            success: false,
            failed: false,
            messages: [],
            formData: {}
        };
    },

    computed: {
        errors() {
            return this.messages.filter(message => message.type === 'error');
        }
    },

    watch: {
        $route() {
            this.resetStatus();
        }
    },

    methods: {
        startLoading() {
            this.isLoading = true;
            this.$emit('loading');
        },

        finishLoading() {
            this.isLoading = false;
            this.$emit('finished');
        },

        setStatusFailed() {
            this.failed = true;
            this.success = false;
            this.$emit('failed');
        },

        setStatusSuccess() {
            this.failed = false;
            this.success = true;
            this.$emit('success');
        },

        resetStatus() {
            this.success = false;
            this.failed = false;
            this.messages = [];
        },

        setErrorState({ errors }) {
            this.resetStatus();

            if (errors) {
                this.messages = formatGqlError(errors);
                this.setStatusFailed();
            }
        }
    }
};
</script>
