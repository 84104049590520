// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/chevron-down.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select{display:grid;grid-template-areas:\"select\";--spacing-y:1rem;--spacing-x:1.125rem;--label-scale:0.77;border:2px solid var(--color-black-30);border-radius:var(--borderRadius-sm);box-shadow:0 0 2px hsla(0,0%,6%,.08),inset 2px 2px 8px rgba(0,0,0,.04);font-size:var(--font-size-md)}.select .dn-input__label,.select .dn-textarea__label,.select .select{color:var(--color-black-50);font-size:inherit}.select .dn-input__field,.select .dn-textarea__field{box-shadow:none}.select[required] .dn-input__label:after,.select[required] .dn-textarea__label:after{color:var(--color-highlight-error);content:\" *\"}.select:focus-within{border-color:var(--color-primary)}.select__control{-webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:transparent;border:0;cursor:pointer;display:grid;font-family:inherit;font-size:inherit;grid-area:select;margin:0;outline:none;padding:var(--spacing-y) var(--spacing-x);width:100%}.select:after{align-self:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100%;content:\"\";grid-area:select;height:1.25rem;justify-self:end;margin-right:var(--spacing-y);width:1.25rem}.select option[disabled]{color:var(--color-black-50)}.dn-form-field[data-has-errors=true] .select{border-color:var(--color-highlight-error)}.select--lightest{background:var(--color-lightest)}.select--compact .select__control{padding:.875rem var(--spacing-x)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
