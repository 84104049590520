<template>
    <div class="select">
        <select
            class="select__control"
            @input="e => $emit('input', e.target.value)"
            @change="e => $emit('change', e.target.value)"
            @blur="e => $emit('blur', e.target.value)"
        >
            <option v-if="placeholder" value="" disabled :selected="!value">
                {{ placeholder }}
            </option>

            <option
                v-for="option in options"
                :key="option.value"
                :selected="option.value === value"
                :value="option.value"
            >
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less" src="./select.less" />
