<template>
    <div class="auth-panel">
        <header
            v-if="$slots.header"
            class="auth-panel__header"
        >
            <slot name="header" />
        </header>

        <div
            v-if="$slots.default"
            class="auth-panel__body"
        >
            <slot />
        </div>

        <div
            v-if="$slots.footer"
            class="auth-panel__footer"
        >
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="less">
.auth-panel {
    position: relative;
    width: 100%;
    border-radius: 4px;
    background: white;
    overflow: hidden;
}

.auth-panel__header,
.auth-panel__body {
    padding: 2.5rem 2rem;
}

.auth-panel__header {
    img {
        max-height: 2.5rem;
        height: 100%;
        width: auto;
    }
}

.auth-panel__header + .auth-panel__body {
    padding-top: 0;
}

.auth-panel__body {
    h1 {
        .typography-h3();
        margin-bottom: .75em;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .auth-links {
            margin: 0;
        }
    }

    p {
        .typography-text();
        color: var(--color-black-50);
        margin-bottom: 1.25rem;
    }
}

.auth-panel__footer {
    font-size: var(--font-size-xs);
    color: var(--color-black-50);

    a {
        color: inherit;
    }
}
</style>
