<template>
    <asc-modal
        class="follow-topics"
        :is-visible="isOpen"
        data-modal-name="follow-topics"
        data-modal-width="medium"
        @close="closeModal"
    >
        <template v-slot:header>
            <h1>Follow topics</h1>
        </template>

        <template v-slot:default>
            <asc-follow-topics-accordion :limit-channels="limitChannels" />
        </template>
    </asc-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import AscModal from '~/patterns/organisms/modal/modal.vue';
import AscFollowTopicsAccordion from '~/patterns/organisms/follow-topics-accordion/follow-topics-accordion.vue';

export default {
    components: {
        AscModal,
        AscFollowTopicsAccordion
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal',
            modalData: 'modal/data'
        }),

        limitChannels() {
            return this.modalData && this.modalData.limitChannels ? this.modalData.limitChannels : null;
        },

        isOpen() {
            return this.activeModal === 'follow-topics';
        }
    },

    methods: {
        closeModal() {
            this.$store.dispatch('modal/close');
        }
    }
};
</script>
