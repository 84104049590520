<template>
    <asc-accordion
        :items="filteredChannels"
        :open-indexes="openAccordionIndexes"
    >
        <template v-slot:titleSuffix="{ item: channel }">
            {{ channel.children.length }} topics
        </template>

        <template v-slot:default="{ item: channel }">
            <topics-grid>
                <follow-topic-button
                    v-for="topic in channel.children"
                    :key="topic.id"
                    :topic="topic"
                    :tint="channel.color"
                />
            </topics-grid>
        </template>
    </asc-accordion>
</template>

<script>
import { mapGetters } from 'vuex';

import TopicsGrid from '~/patterns/molecules/topics-grid/topics-grid.vue';
import FollowTopicButton from '~/patterns/atoms/follow-topic-button/follow-topic-button.vue';

import AscAccordion from '~/patterns/molecules/accordion/accordion.vue';

export default {
    components: {
        FollowTopicButton,
        TopicsGrid,
        AscAccordion
    },

    props: {
        limitChannels: {
            type: Array,
            default: null
        },

        openIndexes: {
            type: Array,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            allChannels: 'topics/channels'
        }),

        filteredChannels() {
            if (!this.limitChannels || !this.limitChannels.length) {
                return this.allChannels;
            } else {
                return this.allChannels?.filter(channel => this.limitChannels.includes(channel.id));
            }
        },

        openAccordionIndexes() {
            if (this.openIndexes && this.openIndexes.length) {
                return this.openIndexes;
            }

            return this.limitChannels && this.limitChannels.length ? [...Array(this.filteredChannels.length).keys()] : [];
        }
    }
};
</script>
