import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a3e429c = () => interopDefault(import('../styleguide/pages/layout/index.vue' /* webpackChunkName: "" */))
const _8f6b9dc4 = () => interopDefault(import('../styleguide/pages/components/index.vue' /* webpackChunkName: "" */))
const _5b4100ff = () => interopDefault(import('../styleguide/pages/elements/index.vue' /* webpackChunkName: "" */))
const _3582127b = () => interopDefault(import('../styleguide/pages/foundation/index.vue' /* webpackChunkName: "" */))
const _a8e2fc10 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1259e7ae = () => interopDefault(import('../pages/channel.vue' /* webpackChunkName: "pages/channel" */))
const _52f8262c = () => interopDefault(import('../pages/channel/_slug/index.vue' /* webpackChunkName: "pages/channel/_slug/index" */))
const _602f49f3 = () => interopDefault(import('../pages/channel/_slug/_type.vue' /* webpackChunkName: "pages/channel/_slug/_type" */))
const _6ea4b657 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _06be08b0 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _d6240058 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _55e3032f = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _28d51c47 = () => interopDefault(import('../pages/members/index.vue' /* webpackChunkName: "pages/members/index" */))
const _7ed59b43 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _090833c7 = () => interopDefault(import('../pages/organisations/index.vue' /* webpackChunkName: "pages/organisations/index" */))
const _e27085da = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _31c968d7 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _4944144d = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _88245cea = () => interopDefault(import('../pages/set-password.vue' /* webpackChunkName: "pages/set-password" */))
const _7b99fb56 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _128e8a9a = () => interopDefault(import('../pages/topic.vue' /* webpackChunkName: "pages/topic" */))
const _7013c718 = () => interopDefault(import('../pages/topic/_slug/index.vue' /* webpackChunkName: "pages/topic/_slug/index" */))
const _7d4aeadf = () => interopDefault(import('../pages/topic/_slug/_type.vue' /* webpackChunkName: "pages/topic/_slug/_type" */))
const _3e3fe016 = () => interopDefault(import('../pages/updates.vue' /* webpackChunkName: "pages/updates" */))
const _2b07ea90 = () => interopDefault(import('../pages/updates/index.vue' /* webpackChunkName: "pages/updates/index" */))
const _53c6cc7c = () => interopDefault(import('../pages/updates/_type/index.vue' /* webpackChunkName: "pages/updates/_type/index" */))
const _1953e65a = () => interopDefault(import('../pages/onboarding/profile.vue' /* webpackChunkName: "pages/onboarding/profile" */))
const _5acd1307 = () => interopDefault(import('../pages/profile/details.vue' /* webpackChunkName: "pages/profile/details" */))
const _3c3dbb33 = () => interopDefault(import('../pages/profile/my-interests.vue' /* webpackChunkName: "pages/profile/my-interests" */))
const _4ef0563d = () => interopDefault(import('../pages/profile/preferences.vue' /* webpackChunkName: "pages/profile/preferences" */))
const _46b9df6a = () => interopDefault(import('../pages/profile/security.vue' /* webpackChunkName: "pages/profile/security" */))
const _69734686 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _79bb846f = () => interopDefault(import('../pages/members/_id.vue' /* webpackChunkName: "pages/members/_id" */))
const _0751927f = () => interopDefault(import('../pages/organisations/_slug.vue' /* webpackChunkName: "pages/organisations/_slug" */))
const _6ce08916 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _57340f0c = () => interopDefault(import('../pages/updates/_type/_slug.vue' /* webpackChunkName: "pages/updates/_type/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/styleguide/layout",
    component: _1a3e429c
  }, {
    path: "/styleguide/components",
    component: _8f6b9dc4
  }, {
    path: "/styleguide/elements",
    component: _5b4100ff
  }, {
    path: "/styleguide",
    component: _3582127b
  }, {
    path: "/about",
    component: _a8e2fc10,
    name: "about"
  }, {
    path: "/channel",
    component: _1259e7ae,
    name: "channel",
    children: [{
      path: ":slug",
      component: _52f8262c,
      name: "channel-slug"
    }, {
      path: ":slug?/:type",
      component: _602f49f3,
      name: "channel-slug-type"
    }]
  }, {
    path: "/community",
    component: _6ea4b657,
    name: "community"
  }, {
    path: "/forgot-password",
    component: _06be08b0,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _d6240058,
    name: "login"
  }, {
    path: "/logout",
    component: _55e3032f,
    name: "logout"
  }, {
    path: "/members",
    component: _28d51c47,
    name: "members"
  }, {
    path: "/onboarding",
    component: _7ed59b43,
    name: "onboarding"
  }, {
    path: "/organisations",
    component: _090833c7,
    name: "organisations"
  }, {
    path: "/partners",
    component: _e27085da,
    name: "partners"
  }, {
    path: "/profile",
    component: _31c968d7,
    name: "profile"
  }, {
    path: "/search",
    component: _4944144d,
    name: "search"
  }, {
    path: "/set-password",
    component: _88245cea,
    name: "set-password"
  }, {
    path: "/sign-up",
    component: _7b99fb56,
    name: "sign-up"
  }, {
    path: "/topic",
    component: _128e8a9a,
    name: "topic",
    children: [{
      path: ":slug",
      component: _7013c718,
      name: "topic-slug"
    }, {
      path: ":slug?/:type",
      component: _7d4aeadf,
      name: "topic-slug-type"
    }]
  }, {
    path: "/updates",
    component: _3e3fe016,
    children: [{
      path: "",
      component: _2b07ea90,
      name: "updates"
    }, {
      path: ":type",
      component: _53c6cc7c,
      name: "updates-type"
    }]
  }, {
    path: "/onboarding/profile",
    component: _1953e65a,
    name: "onboarding-profile"
  }, {
    path: "/profile/details",
    component: _5acd1307,
    name: "profile-details"
  }, {
    path: "/profile/my-interests",
    component: _3c3dbb33,
    name: "profile-my-interests"
  }, {
    path: "/profile/preferences",
    component: _4ef0563d,
    name: "profile-preferences"
  }, {
    path: "/profile/security",
    component: _46b9df6a,
    name: "profile-security"
  }, {
    path: "/",
    component: _69734686,
    name: "index"
  }, {
    path: "/members/:id",
    component: _79bb846f,
    name: "members-id"
  }, {
    path: "/organisations/:slug",
    component: _0751927f,
    name: "organisations-slug"
  }, {
    path: "/:slug",
    component: _6ce08916,
    name: "slug"
  }, {
    path: "/updates/:type/:slug",
    component: _57340f0c,
    name: "updates-type-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
