export default {
    /**
     * Return array of types ready to use in a select box.
     * The labelSingular value is removed, as it's only intended
     * to be used in combination with the typesSingular getter.
     */
    types({ types }) {
        return types.map((type) => {
            const typeClone = { ...type };

            delete typeClone.labelSingular;

            return typeClone;
        });
    },

    /**
     * Return array of types ready to use in a select box.
     * Labels are turned into their singular value instead of the default.
     */
    typesSingular({ types }) {
        return types.map((type) => {
            const typeClone = { ...type };

            typeClone.label = typeClone.labelSingular;
            delete typeClone.labelSingular;

            return typeClone;
        });
    },

    typeBySlug: ({ types }) => slug => types.find(type => type.value === slug),

    eventTypes({ eventTypes }) {
        return eventTypes;
    },

    experienceTypes({ experienceTypes }) {
        return experienceTypes;
    }
};
