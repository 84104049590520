export default () => ({
    menu: {
        isOpen: false,

        primaryRoutes: [
            {
                label: 'Updates for you',
                to: '/updates'
            },
            {
                label: 'Community',
                to: '/community'
            },
            {
                label: 'My account',
                to: '/profile',
                auth: true
            },
            {
                label: 'About',
                to: '/about'
            }
        ],

        secondaryRoutes: [
            {
                label: 'Visit Amsterdam',
                to: '/updates/experience'
            },
            {
                label: 'Discover projects',
                to: '/updates/project'
            },
            {
                label: 'Meet our partners',
                to: '/partners'
            }
        ]
    },

    heroInView: false,

    isSearchOpen: null,

    isPageLoaderVisible: false,

    activeRouteBeforeLogin: null,

    navigation: {
        isCollapsed: true
    }
});
