<template>
    <asc-modal
        :is-visible="isOpen"
        :data-modal-background="step === 2 ? 'grey' : ''"
        data-modal-name="add-update"
        :data-modal-width="step === 3 ? '' : 'small'"
        class="dn-modal--add-update"
        @close="closeModal"
    >
        <template v-slot:header>
            <h1 v-if="step === 1">I want to share</h1>

            <template v-if="step === 2">
                <h1>Create update</h1>

                <asc-select
                    v-model="selectedTypeValue"
                    :options="updateTypesSingular"
                    class="select--lightest select--compact"
                    placeholder="Select a type"
                    novalidate
                />
            </template>

            <h1 v-if="step === 3">Select topics (no more than 5)</h1>
        </template>

        <template v-slot:default>
            <div v-if="step === 1">
                <ul class="add-update__list">
                    <li
                        v-for="type in updateTypesSingular"
                        :key="type.value"
                    >
                        <asc-update-type-link
                            :update-type="type"
                            class="update-type-link--primary"
                            @click="selectType(type)"
                        />
                    </li>
                </ul>
            </div>

            <keep-alive>
                <asc-update-form
                    v-if="step === 2"
                    v-model="formData"
                    :type="selectedTypeValue"
                    @invalidSubmit="isLoading =false"
                    @submit="currentStep = 3"
                    @hasChanges="onHasFormChanges"
                >
                    <template v-slot:submit-label>Add topics</template>
                </asc-update-form>
            </keep-alive>

            <div v-if="step === 3">
                <asc-topics-select v-model="selectedTopics" :max-count="5" />
            </div>

            <div v-if="step === 4">
                <article v-if="updateObject && updateObject.published_at" class="article">
                    <h4>Update created successfully</h4>

                    <p>Thanks for your contribution to Amsterdam Smart City!</p>

                    <p>
                        <asc-button
                            class="dn-button--primary dn-button--inline"
                            @click="resetAndRedirect"
                        >
                            Check out your new update! 🎉
                        </asc-button>
                    </p>
                </article>
                <article v-else class="article">
                    <h4>Update submitted for review</h4>

                    <p>
                        Because this is one of your first updates, one of our moderators will check if everything is in order.<br><br>
                        Once this update is approved, it’ll be visible in everyone’s feed.<br>
                        If this first update is approved, any of your future posts will be immediately published.
                    </p>

                    <p>
                        <asc-button
                            class="dn-button--primary dn-button--inline"
                            @click="resetAndRedirect"
                        >
                            Check out your feed!
                        </asc-button>
                    </p>
                </article>
            </div>

            <transition name="fade">
                <div
                    v-if="isLoading"
                    class="dn-modal__loader"
                >
                    <asc-loader />
                </div>
            </transition>
        </template>

        <template v-slot:footer>
            <div v-if="step === 3">
                <div class="space-between">
                    <asc-button class="dn-button--inline" @click="currentStep = 2">
                        <template v-slot:default><asc-icon icon="arrow-left" /></template>
                    </asc-button>

                    <asc-button
                        class="dn-button--primary dn-button--inline"
                        :disabled="isLoading"
                        @click="submitData"
                    >
                        <template v-slot:default>Create update</template>
                        <template v-slot:after><asc-icon icon="arrow-right" /></template>
                    </asc-button>
                </div>
            </div>
        </template>
    </asc-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import createUpdateMutation from '~/graphql/mutations/update/createUpdate.graphql';
import formatGqlError from '~/helpers/formatGqlError.js';
import { sanitizeUpdateFormData } from '~/helpers/update.js';

import AscLoader from '~/patterns/atoms/loader/loader.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import AscButton from '~/patterns/atoms/button/button.vue';
import AscUpdateTypeLink from '~/patterns/atoms/update-type-link/update-type-link.vue';
import AscSelect from '~/patterns/atoms/select/select.vue';
import AscTopicsSelect from '~/patterns/molecules/topics-select/topics-select.vue';
import AscModal from '~/patterns/organisms/modal/modal.vue';
import AscUpdateForm from '~/patterns/organisms/update-form/update-form.vue';

export default {
    components: {
        AscLoader,
        AscIcon,
        AscButton,
        AscUpdateTypeLink,
        AscModal,
        AscSelect,
        AscTopicsSelect,
        AscUpdateForm
    },

    data() {
        return {
            isLoading: false,
            updateObject: null,
            currentStep: null,
            selectedTypeValue: null,
            selectedTopics: [],
            formData: null,
            hasChanges: false
        };
    },

    computed: {
        ...mapGetters({
            activeModal: 'modal/activeModal',
            modalData: 'modal/data',
            updateTypesSingular: 'update/typesSingular'
        }),

        isOpen() {
            return this.activeModal === 'add-update';
        },

        step() {
            if (this.currentStep) {
                return this.currentStep;
            }

            if (this.modalData && this.modalData.step) {
                return this.modalData.step;
            }

            return 1;
        },

        sanitizedData() {
            const data = sanitizeUpdateFormData(this.formData);

            // Set topics
            data.topics = this.selectedTopics;

            // Set update type
            data.update_type = this.selectedTypeValue;

            return data;
        }
    },

    methods: {
        /**
         * Close and reset the modal.
         * If any changes were made, make the user confirm before closing.
         */
        closeModal(force) {
            let confirmed = true;

            if (this.hasChanges && !force) {
                confirmed = confirm('Are you sure you want to stop creating your update? Any input will be lost.');
            }

            if (confirmed) {
                this.$store.dispatch('modal/close');
                this.resetModal();
            }
        },

        /**
         * Redirect to the newly created update, and reset the modal for reuse.
         */
        resetAndRedirect() {
            if (this.updateObject && this.updateObject.published_at && this.updateObject.slug && this.updateObject.update_type) {
                this.$router.push(`/updates/${this.updateObject.update_type}/${this.updateObject.slug}`);
            } else {
                this.$router.push('/updates');
            }

            // Force modal closure, as route change is not triggered when already on the updates page (and update is not published)
            this.closeModal(true);
        },

        /**
         * Select an update type to work with and redirect to step 2.
         *
         * @param   {Object}  type  Update type object, containing value string.
         */
        selectType(type) {
            this.selectedTypeValue = type.value;
            this.currentStep = 2;
        },

        /**
         * Reset the add update modal to its initial state.
         */
        resetModal() {
            this.currentStep = 1;
            this.isLoading = false;
            this.updateObject = null;
            this.selectedTypeValue = null;
            this.selectedTopics = [];
            this.formData = null;
            this.hasChanges = false;
        },

        /**
         * Submit the data gathered in the different steps to the graphQL api.
         */
        async submitData() {
            this.isLoading = true;

            if (!this.selectedTopics.length) {
                this.isLoading = false;
                this.$store.commit('toasts/addMessage', {
                    message: 'Please select one or more topics for your update.',
                    status: 'error'
                });
                return;
            }

            try {
                const response = await this.$gql.executeQuery(createUpdateMutation, this.sanitizedData);

                if (response.data && response.data.createUpdate) {
                    this.updateObject = response.data.createUpdate;
                    this.$matomo.trackEvent('updates', 'create', this.updateObject.title, this.updateObject.id);
                }

                setTimeout(() => {
                    this.currentStep = 4;
                    this.isLoading = false;

                    // Reset hasChanges variable, so no prompt is shown in step 4 before closing.
                    this.hasChanges = false;
                }, 2000);
            } catch (e) {
                this.isLoading = false;

                const messages = formatGqlError(e);

                messages.forEach((message) => {
                    this.$store.commit('toasts/addMessage', {
                        message: message.text,
                        status: 'error'
                    });
                });
            }
        },

        /**
         * Keep track of whether the update-form has any changes.
         * This value can be used to check if a prompt should be displayed
         * before closing the modal.
         */
        onHasFormChanges(value) {
            this.hasChanges = value;
        }
    }
};
</script>

<style src="./add-update.less" lang="less"></style>
