'use strict';
import Vue from 'vue';
import defaultSEO from '~/constants/seo';

class SEOService {
    getMetaData(seo = {}) {
        if (!seo) {
            return undefined;
        }

        const metaData = {
            title: this.getTitle(seo),
            meta: [
                ...this.getDescriptionField(seo),
                ...this.getFbFields(seo),
                ...this.getTwFields(seo),
                this.getRobots(seo)
            ]
        };

        if (this.getCanonical(seo)) {
            metaData.link = [
                this.getCanonical(seo)
            ];
        }

        return metaData;
    }

    getTitle(seo) {
        if (seo.title) {
            return `${seo.title} - ${defaultSEO.title}`;
        }
    }

    getDescriptionField(seo) {
        if (seo.description) {
            return [{ hid: 'description', name: 'description', content: seo.description }];
        }

        return [];
    }

    getFbFields(seo) {
        if (seo.social && seo.social.facebook) {
            return Object.keys(seo.social.facebook).map((key) => {
                return {
                    hid: 'og:' + key,
                    property: 'og:' + key,
                    content: seo.social.facebook[key]
                };
            });
        }

        const defaults = [];

        // Fall back on regular title
        if (seo.title) {
            defaults.push({ hid: 'og:title', property: 'og:title', content: this.getTitle(seo) });
        }

        // Fall back on regular description
        if (seo.description) {
            return [
                { hid: 'og:description', property: 'og:description', content: seo.description }
            ];
        }

        // Fall back on regular image
        if (seo.image) {
            return [
                { hid: 'og:image', property: 'og:image', content: seo.image }
            ];
        }

        return defaults;
    }

    getTwFields(seo) {
        if (seo.social && seo.social.twitter) {
            return Object.keys(seo.social.twitter).map((key) => {
                return { hid: 'twitter:' + key, name: 'twitter:' + key, content: seo.social.twitter[key] };
            });
        }

        const defaults = [];

        // Fall back on regular title
        if (seo.title) {
            defaults.push({ hid: 'twitter:title', name: 'twitter:title', content: this.getTitle(seo) });
        }

        // Fall back on regular description
        if (seo.description) {
            return [
                { hid: 'twitter:description', name: 'twitter:description', content: seo.description }
            ];
        }

        // Fall back on regular image
        if (seo.image) {
            return [
                { hid: 'twitter:image', name: 'twitter:image', content: seo.image }
            ];
        }

        return defaults;
    }

    getRobots(seo) {
        let content = 'all';

        if (seo.advanced && seo.advanced.robots) {
            content = seo.advanced.robots.join(', ');
        }

        return { hid: 'robots', name: 'robots', content };
    }

    getCanonical(seo) {
        if (seo.advanced && seo.advanced.canonical) {
            return { rel: 'canonical', href: seo.advanced.canonical };
        }

        return false;
    }
}

Vue.use({
    install(VueInstance) {
        VueInstance.prototype.$seo = VueInstance.$seo = new SEOService();
    }
});
