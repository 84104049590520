export default {
    activeModal(state, modalName) {
        state.activeModal = modalName;

        if (typeof document !== 'undefined') {
            document.body.setAttribute('data-modal-open', modalName ? 'true' : 'false');
        }
    },

    data(state, data) {
        state.data = data;
    }
};
