<template>
    <asc-button
        :tint="buttonTint"
        :class="{ 'dn-button--channel': !isSelected }"
        :data-selected="isSelected"
        class="dn-button--inline dn-button--flat"
        v-bind="$attrs"
        @mouseover="hoverActive = true"
        @mouseout="hoverActive = false"
        v-on="$listeners"
    >
        <template v-slot:default>
            <slot>
                {{ topic.title }}
            </slot>
        </template>

        <template v-if="isSelectable" v-slot:after>
            <slot name="after" :hover-active="hoverActive">
                <asc-icon :icon="isSelected ? (hoverActive ? 'cross' : 'check') : 'plus'" />
            </slot>
        </template>
    </asc-button>
</template>
<script>
import AscButton from '~/patterns/atoms/button/button.vue';
import AscIcon from '~/patterns/atoms/icon/icon.vue';
import { isTintValid } from '~/helpers/colors';

export default {
    components: {
        AscButton,
        AscIcon
    },

    props: {
        tint: {
            type: String,
            default: null,
            validator(value) {
                return isTintValid(value);
            }
        },

        isSelected: {
            type: Boolean,
            default: false
        },

        isSelectable: {
            type: Boolean,
            default: true
        },

        topic: {
            type: Object,
            required: true,
            default: null
        }
    },

    data() {
        return {
            hoverActive: false
        };
    },

    computed: {
        buttonTint() {
            // Set an explicit tint first (least expensive)
            if (this.tint) {
                return this.tint;
            }
            // Set a tint from the parent if set
            if (this.topic.parent && this.topic.parent.color) {
                return this.topic.parent.color;
            }
            // Most expensive fallback, look up the channel in the store and set its color
            const channel = this.$store.getters['topics/channelByTopicId'](this.topic.id);
            return channel?.color;
        }
    }
};
</script>

<style src="./topic-button.less" lang="less" scoped></style>
