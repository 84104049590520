export default {
    isMenuOpen: state => state.menu.isOpen,
    primaryMenuRoutes: (state, getters, rootState) => {
        return state.menu.primaryRoutes.filter(route => shouldDisplayRoute(route, rootState));
    },
    secondaryMenuRoutes: (state, getters, rootState) => {
        return state.menu.secondaryRoutes.filter(route => shouldDisplayRoute(route, rootState));
    },

    isNavigationCollapsed: state => state.navigation.isCollapsed,
    heroInView: state => state.heroInView,
    isSearchOpen: state => (route) => {
        if (route?.query?.q?.length) {
            return true;
        }
        return state.isSearchOpen;
    },
    isPageLoaderVisible: state => state.isPageLoaderVisible,
    activeRouteBeforeLogin: state => state.activeRouteBeforeLogin
};

function shouldDisplayRoute(route, rootState) {
    return !route.auth || (route.auth && rootState.auth.loggedIn);
}
